import React, {Component} from 'react';
import NavBar from '../../components/NavBar';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAddressCard, faLock} from '@fortawesome/free-solid-svg-icons';

export default class About extends Component {
  render() {
    return (
      <div>
        <NavBar title="Legal pages" />
        <div className='text-center'>
          <p>Welcome to Felix's legal pages!</p>
          <p>Choose between following pages:</p>
          <Button color="primary" href={`${process.env.PUBLIC_URL}/imprint`} className="mr-3">
            <FontAwesomeIcon icon={faAddressCard} className="mr-2" />
                Imprint
          </Button>
          <Button color="primary" href={`${process.env.PUBLIC_URL}/privacy-policy`}>
            <FontAwesomeIcon icon={faLock} className="mr-2" />
                Privacy Polity
          </Button>
        </div>
      </div>
    );
  }
}

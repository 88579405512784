import React from 'react';

export default function Content() {
  return (
    <div className="container">
      <div style={{textAlign: 'center'}}>
        <p>Folgendes wurde aufgrund des Hosting Standorts des Server auf deutsch verfasst.</p>
        <h2>Impressum</h2>
        <p>
            Angaben gemäß § 5 TMG<br />
            Felix Franz<br />
            Angermaierstraße 28<br />
            97076 Würzburg<br />
            Deutschland<br />
            E-Mail: <a href="mailto:info@felix-franz.com">info@felix-franz.com</a><br />
            Phone: <a href="tel:+49 159 08630536">+49 159 08630536</a>
        </p>
      </div>

      <hr className='mt-5' />

      <h3 className='mt-5'>Wiederspruch gegen Werbe-E-Mails & Werbe-Anrufe</h3>
      <p> Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor. </p>

    </div>
  );
}

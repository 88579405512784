import React from 'react';
import Footer from '../components/Footer';

export default class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      footerHeight: 0,
    };
  }

  render() {
    return (
      <div style={{height: '100%'}}>
        <div style={{minHeight: 'calc(100% - ' + this.state.footerHeight + 'px)'}}>
          {this.props.children}
        </div>
        <Footer onHeightChange={(height) => this.setState({footerHeight: height})} />
      </div>
    );
  }
}

import React, {Component} from 'react';
import {PortfolioElementCard, PortfolioElementModal} from '../PortfolioElement/PortfolioElement';
import {FactList, FactListItem} from '../PortfolioElement/FactList';
import Lightbox from '../../../../components/Lighbox';
import imgThumbnail from './thumbnail.jpg';
import imgFull from './full.png';
import imgDetailHome from './detail-home.png';
import imgDetailProtocol from './detail-protocol.png';
import imgDetailEvent from './detail-event.png';
import imgDetailWorship from './detail-worship-import.png';

const id='minis-webapp';

export class Card extends Component {
  render() {
    return (
      <PortfolioElementCard id={id} name="Minis St. Lioba Webapp" thumbnail={imgThumbnail} thumbnailAlt="Minis St. Lioba">
        A complete new web presence for the Minis of St. Lioba
      </PortfolioElementCard>
    );
  }
}

const IMAGES = [
  {
    src: imgFull,
    caption: 'Minis St. Lioba Logo',
  },
  {
    src: imgDetailHome,
    caption: 'Start point of the internal website',
  },
  {
    src: imgDetailProtocol,
    caption: 'Protocol page',
  },
  {
    src: imgDetailEvent,
    caption: 'Event page',
  },
  {
    src: imgDetailWorship,
    caption: 'Worship import page',
  },
];

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.setPhotoIndex = this.setPhotoIndex.bind(this);

    this.state = {
      photoIndex: false,
    };
  }

  setPhotoIndex(index) {
    this.setState({photoIndex: index});
  }

  render() {
    return (
      <PortfolioElementModal id={id} name="Minis St. Lioba Webapp" description="A complete new web presence for the Minis of St. Lioba">
        <Lightbox images={IMAGES} photoIndex={this.state.photoIndex} onPhotoIndexChange={this.setPhotoIndex} />
        <img className="img-fluid border img-clickable" src={imgFull} alt="Minis St. Lioba Logo" onClick={() => this.setPhotoIndex(0)} />

        <p>
          Together with two fellow students, I created a complete new web presence for the acolytes of St. Lioba. This includes a wordpress installation for representation purpose,
          other small public websites for contect form or about page and an internal completely self developed webapp, which was the main part of this project.
          <br />
          You can find all public parts of the website on <a href="https://www.minis-lioba.de" target="_blank" rel="noopener noreferrer">www.minis-lioba.de</a>.
        </p>
        <p>
          The internal webapp contains features for planning, internal publication of information, reminding, communication and sharing media files. Therefore we used a <a href="https://www.mysql.com" target="_blank" rel="noopener noreferrer">MySQL</a> database and developed a <a href="https://nodejs.org" target="_blank" rel="noopener noreferrer">NodeJS</a> backend and an <a href="https://angular.io" target="_blank" rel="noopener noreferrer">Angular</a> frontend. For achieving the best result we e.g. provide an ical feed for every user or send push notifications directly to the browser. The internal sites are optimised for mobile usage by making it possible to run the website as webapp. In general we focused on getting the best user experience possible.
        </p>
        <p>I worked on this project not only as backend developer but also project manager.</p>

        <h3>Screenshots</h3>
        <div className="row justify-content-md-center">
          <div className="col col-md-3 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailHome} alt="Start point of the internal website" onClick={() => this.setPhotoIndex(1)} />
          </div>
          <div className="col col-md-3 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailProtocol} alt="Protocol page" onClick={() => this.setPhotoIndex(2)} />
          </div>
          <div className="col col-md-3 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailEvent} alt="Event page" onClick={() => this.setPhotoIndex(3)} />
          </div>
          <div className="col col-md-3 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailWorship} alt="Worship import page" onClick={() => this.setPhotoIndex(4)} />
          </div>
        </div>

        <h3>Ongoing</h3>
        <p>
          Since July 2018 I continued this project on my own doing bug fixes and improvements like creating an <a href="https://play.google.com/store/apps/details?id=de.minis_lioba.my.app.android" target="_blank" rel="noopener noreferrer">Android app</a>.
        </p>
        <p>
          Because the webapp reduces work other acolytes are also interested in the webapp.
          For example the acolytes of <a href="https://plan.minis-burkard.de" target="_blank" rel="noopener noreferrer">St. Burkard</a> started using it in February 2019.
        </p>

        <FactList>
          <FactListItem>Co-workers: <a href="https://gitlab.com/sebastianfreund" target="_blank" rel="noopener noreferrer">Sebastian</a> & <a href="http://romanboehm.com" target="_blank" rel="noopener noreferrer">Roman</a></FactListItem>
          <FactListItem>Development: March - July 2018</FactListItem>
          <FactListItem>Maintenance & Improvements: Since July 2018</FactListItem>
          <FactListItem>Go Live: October 2018</FactListItem>
          <FactListItem>Client: <a href="https://www.minis-lioba.de" target="_blank" rel="noopener noreferrer">Minis St. Lioba</a></FactListItem>
          <FactListItem>Category: Software Developement</FactListItem>
        </FactList>
      </PortfolioElementModal>
    );
  }
}

export default {Card, Modal};

import React, {Component} from 'react';
import {PortfolioElementCard, PortfolioElementModal} from '../PortfolioElement/PortfolioElement';
import {FactList, FactListItem} from '../PortfolioElement/FactList';
import Lightbox from '../../../../components/Lighbox';
import imgThumbnail from './thumbnail.jpg';
import imgFull from './full.png';
import imgDetailWindowed from './detail-generator_windowedMode.PNG';
import imgDetailContractConfiguration from './detail-generator_contractConfiguration.png';
import imgDetailTests from './detail-generator_tests.png';
import imgDetailDeployment from './detail-generator_deployment.PNG';

const id = 'smartcontract-generator';

export class Card extends Component {
  render() {
    return (
      <PortfolioElementCard id={id} name="Smart Contract Generator" thumbnail={imgThumbnail} thumbnailAlt="Smart Contract Generator">
        A template based generator for <a href="https://www.ethereum.org" target="_blank" rel="noopener noreferrer">Ethereum</a> Smart Contracts
      </PortfolioElementCard>
    );
  }
}

const IMAGES = [
  {
    src: imgFull,
    caption: 'Smart Contract Generator',
  },
  {
    src: imgDetailWindowed,
    caption: 'Execution in windowed mode',
  },
  {
    src: imgDetailContractConfiguration,
    caption: 'Smart Contract template configuration',
  },
  {
    src: imgDetailTests,
    caption: 'Test execution',
  },
  {
    src: imgDetailDeployment,
    caption: 'Deployment using browser Extension <a>MetaMask</a>',
  },
];

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.setPhotoIndex = this.setPhotoIndex.bind(this);

    this.state = {
      photoIndex: false,
    };
  }

  setPhotoIndex(index) {
    this.setState({photoIndex: index});
  }

  render() {
    return (
      <PortfolioElementModal id={id} name="Smart Contract Generator" description="A template based generator for Ethereum Smart Contracts">
        <Lightbox images={IMAGES} photoIndex={this.state.photoIndex} onPhotoIndexChange={this.setPhotoIndex} />
        <img className="img-fluid border img-clickable" src={imgFull} alt="Smart Contract Generator" onClick={() => this.setPhotoIndex(0)} />

        <p>
          This project is available on <a href="https://gitlab.com/FelixFranz/FHWS/smart-contract-generation/generator" target="_blank" rel="noopener noreferrer">Gitlab</a>, <a href="https://hub.docker.com/r/felixfranz/smart-contract-generator" target="_blank" rel="noopener noreferrer">Docker</a> and <a href="https://www.npmjs.com/package/smart-contract-generator" target="_blank" rel="noopener noreferrer">npm</a>!
        </p>
        <p>
          As part of my bachelor thesis I created a template based generator for Ethereum Smart Contracts. With this generator it should be possible for anyone to create own Smart Contracts.
        </p>
        <p>
          The generator uses pre-implemented templates to create a Smart Contract depening on the user's Smart Contract configuration.
          Tests, that fits perfectly to the generate Smart contracts will also be generated and also executed inside the generator.
        </p>
        <p>
          For this generator lots of technologies are used.
          It was decided to create a webapp to build a as easy to use as possible solution.
          To compile or test the Smart Contract <a href="https://truffleframework.com/truffle" target="_blank" rel="noopener noreferrer">Truffle</a> is used.
          The backend, that does the generation, compilation, testing and exporting stuff, <a href="https://nodejs.org" target="_blank" rel="noopener noreferrer">node.js</a> is used.
          The frontend is implemented in <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">react</a>.
        </p>
        <p>
          To improve the execution the Generator can be used using a hosted solution, that means a service provider hosts the generator for you.
          A containerized solution using a <a href="https://www.docker.com/" target="_blank" rel="noopener noreferrer">Docker</a> Image is also available.
          It is also possible to run the generator locally in headless or in windowed mode.
        </p>
        <h3>Screenshots</h3>
        <div className="row justify-content-md-center">
          <div className="col col-md-3 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailWindowed} alt="Execution in windowed mode" onClick={() => this.setPhotoIndex(1)} />
          </div>
          <div className="col col-md-3 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailContractConfiguration} alt="Smart Contract template configuration" onClick={() => this.setPhotoIndex(2)} />
          </div>
          <div className="col col-md-3 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailTests} alt="Test execution" onClick={() => this.setPhotoIndex(3)} />
          </div>
          <div className="col col-md-3 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailDeployment} alt="Deployment using browser Extension MetaMask" onClick={() => this.setPhotoIndex(4)} />
          </div>
        </div>

        <FactList>
          <FactListItem>Development: October 2018 - February 2019</FactListItem>
          <FactListItem>Client: <a href="https://fiw.fhws.de" target="_blank" rel="noopener noreferrer">Computer Science Faculty of FHWS</a></FactListItem>
          <FactListItem>Category: Software Developement</FactListItem>
          <FactListItem>External information: <a href="https://gitlab.com/FelixFranz/FHWS/smart-contract-generation/generator" target="_blank" rel="noopener noreferrer">Gitlab</a>, <a href="https://hub.docker.com/r/felixfranz/smart-contract-generator" target="_blank" rel="noopener noreferrer">Docker</a> & <a href="https://www.npmjs.com/package/smart-contract-generator" target="_blank" rel="noopener noreferrer">npm</a></FactListItem>
        </FactList>
      </PortfolioElementModal >
    );
  }
}

export default {Card, Modal};

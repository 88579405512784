import React, {Component} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Page from './pages/Page';
import Identity from './pages/Identity/Identity';
import About from './pages/About/About';
import Imprint from './pages/Imprint/Imprint';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';

export default class Router extends Component {
  render() {
    const rootPath = process.env.PUBLIC_URL !== '' ? process.env.PUBLIC_URL.match(/^https?:\/\/((?!\/).)*(.*)$/)[2] : '';
    return (
      <BrowserRouter>
        <Switch>
          <Route path={`${rootPath}/identity`} render={(props) => <Page {...props}><Identity {...props} /></Page>} />
          <Route path={`${rootPath}/about`} render={(props) => <Page {...props}><About {...props} /></Page>} />
          <Route path={`${rootPath}/imprint`} render={(props) => <Page {...props}><Imprint {...props} /></Page>} />
          <Route path={`${rootPath}/privacy-policy`} render={(props) => <Page {...props}><PrivacyPolicy {...props} /></Page>} />
          <Route exact path='*' render={(props) => window.location.href = `${process.env.PUBLIC_URL}/identity${window.location.search}`} />
        </Switch>
      </BrowserRouter>
    );
  }
}

import React, {Component} from 'react';
import {Modal, Button} from 'reactstrap';
import './PortfolioElementModal.css';

export default class PortfolioElementModal extends Component {
  render() {
    return (
      <Modal isOpen={window.location.hash === ('#portfolio/' + this.props.id)} className="portfolio-modal" aria-hidden="true" zIndex="999">
        <a className="close-modal" href="#portfolio">
          <div className="lr">
            <div className="rl"></div>
          </div>
        </a>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="modal-body">
                <h2 className="text-uppercase">{this.props.name}</h2>
                <p className="item-intro text-muted">{this.props.description}</p>
                {this.props.children}
                <Button color="primary" href="#portfolio">
                  <i className="fa fa-times"></i>
                  Close Project</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

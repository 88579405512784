import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import Button from 'reactstrap/lib/Button';
import Collapse from 'reactstrap/lib/Collapse';
import {ITEM_COUNT} from '../../../../Constants';
import {TimelineElement, TimelineElementText} from './Timeline';
import './TimelineBase.css';

export default class TimelineBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle({target}) {
    this.setState({open: !this.state.open}, () => {
      if (!this.state.open) {
        setTimeout(() => target.scrollIntoView({behavior: 'smooth', block: 'center'}), 250);
      }
    });
  }

  render() {
    const children = this.props.children;

    let originElement;
    if (children.length >= ITEM_COUNT) {
      originElement = (<>
        <Collapse isOpen={this.state.open} tag="ul" className="timeline timeline-appender mb-0">
          {children.slice(ITEM_COUNT)}
        </Collapse>
        <ul className="timeline timeline-appender mb-0">
          <TimelineElement>
            <TimelineElementText onClick={this.onToggle}>
              <Button color="primary" className="rounded-circle mt-lg-2" size="lg" onClick={this.onToggle}>
                <FontAwesomeIcon className="mt-lg-3" icon={this.state.open ? faChevronUp : faChevronDown} />
              </Button>
            </TimelineElementText>
          </TimelineElement>
        </ul>
      </>);
    } else {
      originElement = (
        <ul className="timeline timeline-appender mb-0">
          <TimelineElement>
            <TimelineElementText>Beginning<br />of the<br />story!</TimelineElementText>
          </TimelineElement>
        </ul>);
    }


    return (
      <div className="row">
        <div className="col-lg-12">
          <ul className="timeline mb-0">
            {children.slice(0, ITEM_COUNT)}
          </ul>
          {originElement}
        </div>
      </div>
    );
  }
}

import React, {Component} from 'react';
import {PortfolioElementCard, PortfolioElementModal} from '../PortfolioElement/PortfolioElement';
import {FactList, FactListItem} from '../PortfolioElement/FactList';
import Lightbox from '../../../../components/Lighbox';
import imgThumbnail from './thumbnail.png';
import imgFull from './full.png';
import imgDetailLogin from './detail-login.png';
import imgDetailArchive from './detail-archive.png';
import imgDetailEditOffer from './detail-edit_offer.png';
import imgDetailNewOffer from './detail-new_offer.png';
import imgDetailSelectOutput from './detail-select_output.png';
import imgDetailGenerateMail from './detail-generate_mail.png';
import imgDetailLawTemplates from './detail-law_templates.png';
import imgDetailUserManagment from './detail-user_management.png';

const id = 'offer-builder';

export class Card extends Component {
  render() {
    return (
      <PortfolioElementCard id={id} name="Offer Builder" thumbnail={imgThumbnail} thumbnailAlt="Offer builder">
          A builder that automatically generates multiple outputs for offers
      </PortfolioElementCard>
    );
  }
}

const IMAGES = [
  {
    src: imgFull,
    caption: 'Offer Builder',
  },
  {
    src: imgDetailLogin,
    caption: 'Login Screen',
  },
  {
    src: imgDetailArchive,
    caption: 'Overview about created offers',
  },
  {
    src: imgDetailEditOffer,
    caption: 'Edit an offer',
  },
  {
    src: imgDetailNewOffer,
    caption: 'Create a new offer',
  },
  {
    src: imgDetailSelectOutput,
    caption: 'Select an output type',
  },
  {
    src: imgDetailGenerateMail,
    caption: 'Generate Mail Newsletter',
  },
  {
    src: imgDetailLawTemplates,
    caption: 'Law Templates',
  },
  {
    src: imgDetailUserManagment,
    caption: 'User Management',
  },
];

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.setPhotoIndex = this.setPhotoIndex.bind(this);

    this.state = {
      photoIndex: false,
    };
  }

  setPhotoIndex(index) {
    this.setState({photoIndex: index});
  }

  render() {
    return (
      <PortfolioElementModal id={id} name="Offer Builder" description="A builder that automatically generates multiple outputs for offers">
        <Lightbox images={IMAGES} photoIndex={this.state.photoIndex} onPhotoIndexChange={this.setPhotoIndex} />
        <img className="img-fluid border img-clickable" src={imgFull} alt="Offer Builder" onClick={() => this.setPhotoIndex(0)} />

        <p>
            A local car dealer contacted us with the problem that he always has to design offers differently for different platforms.
        </p>

        <p>
              We therefore created a web application to solve this problem.
              After entering various information once, a flyer, a blog entry, a mail newsletter and a social post were automatically generated.
        </p>

        <h3>Screenshots</h3>
        <div className="row justify-content-md-center">
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailLogin} alt="Login Screen" onClick={() => this.setPhotoIndex(1)} />
          </div>
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailArchive} alt="Overview about created offers" onClick={() => this.setPhotoIndex(2)} />
          </div>
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailEditOffer} alt="Edit an offer" onClick={() => this.setPhotoIndex(3)} />
          </div>
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailNewOffer} alt="Create a new offer" onClick={() => this.setPhotoIndex(4)} />
          </div>
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailSelectOutput} alt="Select an output type" onClick={() => this.setPhotoIndex(5)} />
          </div>
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailGenerateMail} alt="Generate Mail Newsletter" onClick={() => this.setPhotoIndex(6)} />
          </div>
          <div className="col col-md-3 col-sm-66">
            <img className="img-fluid border img-clickable" src={imgDetailLawTemplates} alt="Law Templates" onClick={() => this.setPhotoIndex(7)} />
          </div>
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailUserManagment} alt="User Management" onClick={() => this.setPhotoIndex(8)} />
          </div>
        </div>

        <FactList>
          <FactListItem>Co-workers: <a href="https://gitlab.com/fertigt" target="_blank" rel="noopener noreferrer">Tobias</a>, <a href="https://gitlab.com/andreasschuetz" target="_blank" rel="noopener noreferrer">Andreas</a></FactListItem>
          <FactListItem>Development: March 2019 - June 2020</FactListItem>
          <FactListItem>Client: Local car dealer</FactListItem>
          <FactListItem>Category: Software Developement</FactListItem>
        </FactList>
      </PortfolioElementModal >
    );
  }
}

export default {Card, Modal};


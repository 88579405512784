import React, {Component} from 'react';
import reactInview from 'react-inview-js';
import TokenPage from './TokenPage';

class Section extends Component {
  render() {
    if (this.props.elementIsInView) {
      this.props.onNavigated(this.props.id);
    }
    return (
      <section id={this.props.id} className={this.props.className || ''}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">{this.props.heading}</h2>
              <h3 className="section-subheading text-muted">{this.props.showPasswordPage ? 'Hidden for privacy purpose' : this.props.subheading}</h3>
            </div>
          </div>
          {this.props.showPasswordPage ? <TokenPage /> : this.props.content}
        </div>
      </section>
    );
  }
}

export default reactInview({offsetY: 0.8, showGuides: false, fullElementInView: false})(Section);

import React from 'react';
import './Head.css';
import background from './background.jpg';
import avatar from './avatar.svg';
import reactInview from 'react-inview-js';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowCircleDown} from '@fortawesome/free-solid-svg-icons';
import Typed from 'react-typed';

const id = 'head';
const intros = [
  'Junior IT-Infrastruktur Manager at Union Investment.',
  'Master of Science.\nBachelor of Engineering.',
  'Azure Specialist.',
  'Software Developer.',
  'NodeJs Expert.',
  'Containerization Specialist.',
  'DevOps Pro.',
  'Kubernetes Expert.',
];

class Header extends React.Component {
  render() {
    if (this.props.elementIsInView) {
      this.props.onNavigated(id);
    }
    return (
      <header id={id} className="masthead" style={{backgroundImage: 'url(' + background + ')'}}>
        <div className="container">
          <div className="intro-text">
            <img src={avatar} alt="Avatar" className="rounded-circle intro-avatar" />
            <div className="intro-heading text-uppercase mt-4">Felix Franz</div>
            <div className="intro-lead-in">
              <Typed strings={intros} typeSpeed={70} backDelay={2000} backSpeed={40} loop style={{whiteSpace: 'pre-wrap'}}/>
            </div>
          </div>
          <div className="intro-button-container">
            <Button outline color="primary" className="rounded-circle js-scroll-trigger intro-button" href="#portfolio" onClick={this.props.onNavigation}>
              <FontAwesomeIcon icon={faArrowCircleDown} style={{pointerEvents: 'none'}}/>
            </Button>
          </div>
        </div>
      </header>
    );
  }
}

export default reactInview({offsetY: 0.8, showGuides: false, fullElementInView: false})(Header);

import React, {Component} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default class LightboxExample extends Component {
  render() {
    const {photoIndex, images} = this.props;

    return (
      <div>
        {(typeof (photoIndex) === 'number') && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images.length > 1 ? images[(photoIndex + 1) % images.length].src : undefined}
            prevSrc={images.length > 1 ? images[(photoIndex + images.length - 1) % images.length].src : undefined}
            onCloseRequest={() => this.props.onPhotoIndexChange(false)}
            onMovePrevRequest={() =>this.props.onPhotoIndexChange((photoIndex + images.length - 1) % this.props.images.length)}
            onMoveNextRequest={() => this.props.onPhotoIndexChange((photoIndex + 1) % images.length)}
            imageCaption={images[photoIndex].caption}
          />
        )}
      </div>
    );
  }
}

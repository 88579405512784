import React from 'react';
import {Navbar, NavbarBrand, Nav, NavItem, Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHandPointLeft} from '@fortawesome/free-solid-svg-icons';

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.onNavigateTop = this.onNavigateTop.bind(this);
    this.onGoBack = this.onGoBack.bind(this);
  }

  onNavigateTop() {
    window.scrollTo( {top: 0, left: 0, behavior: 'smooth', block: 'start'});
  }

  onGoBack() {
    window.history.back();
    window.close();
  }

  render() {
    return (
      <div>
        <Navbar bg="dark" className={'fixed-top pl-5 pr-5 navbar-shrink'} id="mainNav" style={{zIndex: 998}}>
          <div className="container">
            <NavbarBrand onClick={this.onNavigateTop} style={{cursor: 'pointer'}}>{this.props.title || 'Felix\'s Webiste'}</NavbarBrand>
            <Nav className="text-uppercase float-right" navbar>
              <NavItem>
                <Button color="primary" onClick={this.onGoBack}>
                  <FontAwesomeIcon icon={faHandPointLeft} />
                  <span className="ml-2">Back</span>
                </Button>
              </NavItem>
            </Nav>
          </div>
        </Navbar>
        <div style={{height: '5em'}} />
      </div >
    );
  }
}

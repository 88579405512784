import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons';

export default class SocialButton extends Component {
  render() {
    return (
      <div className={this.props.divClass}>
        <a target="_blank" rel="noopener noreferrer" href={this.props.href} className="custom-link-hovering">
          <span className="fa-stack fa-4x">
            <FontAwesomeIcon icon={faCircle} className="fa-stack-2x text-primary" />
            <FontAwesomeIcon icon={this.props.logo} className="fa-stack-1x fa-inverse" />
          </span>
          <h4 className="service-heading">{this.props.heading}</h4>
          <p className="text-muted">{this.props.subheading}</p>
        </a>
      </div>
    );
  }
}

import React from 'react';
import {Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';


export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onNavigation = this.onNavigation.bind(this);
    this.onNavigated = this.onNavigated.bind(this);
    this.navbarShrink = this.navbarShrink.bind(this);
    this.state = {
      isOpen: false,
      navbarShrink: false,
      active: window.location.hash.substr(1),
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', this.navbarShrink);
    document.addEventListener('navigated', this.onNavigated);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.navbarShrink);
    document.removeEventListener('navigated', this.onNavigated);
  }

  navbarShrink(e) {
    if (window.pageYOffset > 100) {
      this.setState({navbarShrink: true});
    } else {
      this.setState({navbarShrink: false});
    }
  };


  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  onNavigation(e) {
    this.setState({
      isOpen: false,
    });
    this.props.onNavigation(e);
  }

  onNavigated(e) {
    // ToDo fix thrown error (don't know why!)
    this.setState({
      active: e.detail.hash,
    });
  }

  render() {
    return (
      <div>
        <Navbar bg="dark" expand="lg" className={'fixed-top pl-5 pr-5' + (this.state.navbarShrink ? ' navbar-shrink' : '')} id="mainNav" style={{zIndex: 998}}>
          <div className="container">
            <NavbarBrand href="#head" onClick={this.onNavigation}>Felix Franz</NavbarBrand>
            <NavbarToggler onClick={this.toggle}>
              <span className="mr-1">Menu</span>
              <FontAwesomeIcon icon={faBars} />
            </NavbarToggler>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto text-uppercase" navbar>
                <NavItem>
                  <NavLink href="#portfolio" onClick={this.onNavigation} className={this.state.active === 'portfolio' ? 'active' : ''}>Portfolio</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#social" onClick={this.onNavigation} className={this.state.active === 'social' ? 'active' : ''}>Social</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#certificates" onClick={this.onNavigation} className={this.state.active === 'certificates' ? 'active' : ''}>Certificates</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#publications" onClick={this.onNavigation} className={this.state.active === 'publications' ? 'active' : ''}>Publications</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#work" onClick={this.onNavigation} className={this.state.active === 'work' ? 'active' : ''}>Work</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#volunteering" onClick={this.onNavigation} className={this.state.active === 'volunteering' ? 'active' : ''}>Volunteering</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#education" onClick={this.onNavigation} className={this.state.active === 'education' ? 'active' : ''}>Education</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#contact" onClick={this.onNavigation} className={this.state.active === 'contact' ? 'active' : ''}>Contact</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}

import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLinkedin, faFacebook, faYoutube, faSkype, faSnapchat, faInstagram} from '@fortawesome/free-brands-svg-icons';
import './Footer.css';


export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.updateHeight = this.updateHeight.bind(this);
  }

  updateHeight() {
    this.props.onHeightChange(this.refs.footer.clientHeight);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateHeight);

    const TIMEOUT = 100;
    let callback = null;
    callback = (t) => {
      if (this.refs.footer.clientHeight > 0) {
        this.updateHeight();
      } else {
        setTimeout(callback, TIMEOUT);
      }
    };
    setTimeout(callback, TIMEOUT);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  render() {
    return (
      <footer ref="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <span className="copyright">Copyright &copy; 2016 - {new Date().getFullYear()} <a href={`${process.env.PUBLIC_URL}/`}>Felix Franz</a></span>
            </div>
            <div className="col-md-6">
              <ul className="list-inline social-buttons">
                <li className="list-inline-item">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/felix-franz/">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/felix.franz.9250">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/felixf25">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCCIZ0TNMhdae-EXcQ_IiRjQ">
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="skype:ffelix25?chat">
                    <FontAwesomeIcon icon={faSkype} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.snapchat.com/add/felixfranz25">
                    <FontAwesomeIcon icon={faSnapchat} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul className="list-inline quicklinks">
                <li className="list-inline-item">
                  <a href={`${process.env.PUBLIC_URL}/imprint`} style={{fontWeight: 'bold'}}>Imprint</a>
                </li>
                <li className="list-inline-item">
                  <a href={`${process.env.PUBLIC_URL}/privacy-policy`} style={{fontWeight: 'bold'}}>Privacy policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

import React, {Component} from 'react';

export default class PortfolioElementCard extends Component {
  render() {
    return (
      <div className="col-md-4 col-sm-6 portfolio-item">
        <a className="portfolio-link" data-toggle="modal" href={'#portfolio/' + this.props.id}>
          <div className="portfolio-hover">
            <div className="portfolio-hover-content">
              <i className="fa fa-plus fa-3x"></i>
            </div>
          </div>
          <img className="img-fluid" src={this.props.thumbnail} alt={this.props.thumbnailAlt} />
        </a>
        <div className="portfolio-caption">
          <h4>{this.props.name}</h4>
          <p className="text-muted">{this.props.children}</p>
        </div>
      </div>
    );
  }
}

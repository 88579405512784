import React, {Component} from 'react';

export default class TimelineElementPanel extends Component {
  render() {
    return (
      <div className="timeline-panel">
        <div className="timeline-heading">
          <h4>{this.props.time}</h4>
          <h4 className="subheading" style={{whiteSpace: 'pre-wrap'}}>{this.props.name}</h4>
        </div>
        <div className="timeline-body">
          {this.props.children}
        </div>
      </div>

    );
  }
}

import React, {Component} from 'react';
import {PortfolioElementCard, PortfolioElementModal} from '../PortfolioElement/PortfolioElement';
import {FactList, FactListItem} from '../PortfolioElement/FactList';
import Lightbox from '../../../../components/Lighbox';
import imgThumbnail from './thumbnail.png';
import imgFull from './full.png';
import imgDetailKibana from './detail-kibana.png';
import imgDetailCockpit from './detail-cockpit.png';

const id = 't-pot';

export class Card extends Component {
  render() {
    return (
      <PortfolioElementCard id={id} name="T-Pot" thumbnail={imgThumbnail} thumbnailAlt="T-Pot">
        Improving IT security at universities
      </PortfolioElementCard>
    );
  }
}

const IMAGES = [
  {
    src: imgFull,
    caption: 'T-Pot Logo',
  },
  {
    src: imgDetailKibana,
    caption: 'Kibana Dashboard showing attacks',
  },
  {
    src: imgDetailCockpit,
    caption: 'Cockpit for administration',
  },
];

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.setPhotoIndex = this.setPhotoIndex.bind(this);

    this.state = {
      photoIndex: false,
    };
  }

  setPhotoIndex(index) {
    this.setState({photoIndex: index});
  }

  render() {
    return (
      <PortfolioElementModal id={id} name="T-Pot" description="Improving IT security at universities">
        <Lightbox images={IMAGES} photoIndex={this.state.photoIndex} onPhotoIndexChange={this.setPhotoIndex} />
        <img className="img-fluid border img-clickable" src={imgFull} alt="T-Pot" onClick={() => this.setPhotoIndex(0)} style={{maxWidth: '75vh', maxHeight: '75vh'}} />

        <p>
        As part of my master's thesis, I dealt with the topic of honeypots.
I examined the questions of how a honeypot solution can be established at a university and whether or when such a solution makes sense for the university.
I used the <a href="https://github.com/telekom-security/tpotce" target="_blank" rel="noopener noreferrer">T-Pot</a>, Telekom's honeypot solution, to implement a practical solution to answer these questions.
        </p>

        <h3>Screenshots</h3>
        <div className="row justify-content-md-center">
          <div className="col col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailKibana} alt="Kibana Dashboard showing attacks" onClick={() => this.setPhotoIndex(1)} />
          </div>
          <div className="col col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailCockpit} alt="Cockpit for administration" onClick={() => this.setPhotoIndex(2)} />
          </div>
        </div>

        <FactList>
          <FactListItem>Development: October 2020 - May 2021</FactListItem>
          <FactListItem>Client: <a href="https://fiw.fhws.de" target="_blank" rel="noopener noreferrer">Computer Science Faculty of FHWS</a></FactListItem>
          <FactListItem>Category: IT-Security, Software Management</FactListItem>
          <FactListItem>External information: <a href="https://github.com/telekom-security/tpotce" target="_blank" rel="noopener noreferrer">Github development page</a></FactListItem>
        </FactList>
      </PortfolioElementModal >
    );
  }
}

export default {Card, Modal};


import queryString from 'query-string';
import {SHA512 as sha512, enc} from 'crypto-js';

const TOKENS = [
  'de76de9abc91dca3be51af06e75d704a17ef7dcff771d543070be10941869e14389f5478af97bef567dd566947074b129108ee3600f7a1d3e923423d5b18ec59', // test_35rD8JDCTXcVcu2NgA2oi7kPnT9e8RPV9YyQtTyJToe9ytFNxE
  '32a306aba3a183d0d749a695f9fd786639b25e864a15ca498b5648cdcab02d6b6ed673210307c3a085b52bc1fa73ea15f56dfbacda4b4538982e4d955d08f3b7', // family_8CCdKgcAYShcwemWFh5kjRtfEmvcgjp92n8s6ap65sZfhSNM7o
  'e913ce9d1a145f84e528e7a4f1d68d67d791c4ccf14ba146dee9c249326e4138e41a8c50d646fde9d74dbf268e970fdd4c9bdacf7f2f238ccd69b67021f9f375', // union_1a9dj28adjh23dj9sdSWDjasd0asjd1T2ss2SAJasd1mvpqfdtT
  '722cf3d5ba444eee0d87b460db5153d84211fa75ad68fbbd8a58cf4f4f98199de87f64ada823065a96ad59a6843f038d2166b9ee3fb48391a15ea28353d737b4', // union_buddy_nina_geCyso2djvvqkbsmEm2TKLPP4GH8XspRBQP49oNeiqafjPX6Lt
];

export default class Token {
  static isValid() {
    const query = queryString.parse(window.location.search);

    // console.log(sha512(query.token).toString(enc.Hex))   // uncomment for getting token :)

    return !!query.token && TOKENS.includes(sha512(query.token).toString(enc.Hex));
  }
}

import React, {Component} from 'react';
import {PortfolioElementCard, PortfolioElementModal} from '../PortfolioElement/PortfolioElement';
import {FactList, FactListItem} from '../PortfolioElement/FactList';
import Lightbox from '../../../../components/Lighbox';
import imgThumbnail from './thumbnail.png';
import imgFullLohronplan from './full-lohronplan.png';
import imgDetailLohronplanAppHome from './detail-lohronplan-app-home.png';
import imgDetailLohronplanAppProducts from './detail-lohronplan-app-products.png';
import imgDetailLohronplanAppProduct from './detail-lohronplan-app-product.png';
import imgDetailLohronplanAppInformation from './detail-lohronplan-app-information.png';
import imgDetailLohronplanAppNews from './detail-lohronplan-app-news.png';
import imgDetailLohronplanAppAccount1 from './detail-lohronplan-app-account1.png';
import imgDetailLohronplanAppAccount2 from './detail-lohronplan-app-account2.png';
import imgDetailLohronplanPortalDashboard from './detail-lohronplan-portal-dashboard.png';
import imgDetailLohronplanPortaProduct from './detail-lohronplan-portal-product.png';
import imgDetailLohronplanPortalInformation from './detail-lohronplan-portal-information.png';
import imgDetailLohronplanDocumentation from './detail-lohronplan-documentation.png';
import imgDetailLohronplanApi from './detail-lohronplan-api.png';
import imgDetailYappAppCities from './detail-yapp-app-cities.png';
import imgDetailYappAppFaqs from './detail-yapp-app-faqs.png';
import imgDetailYappAppAccount from './detail-yapp-app-account.png';
import imgDetailYappPortalAccount from './detail-yapp-portal-account.png';
import imgDetailYappPortalNewsintegration from './detail-yapp-portal-newsintegration.png';
import imgDetailYappPortalEventintegration from './detail-yapp-portal-eventintegration.png';
import imgDetailYappPortalFaqs from './detail-yapp-portal-faqs.png';
import imgDetailYappPortalRolemanagement from './detail-yapp-portal-rolemanagement.png';

const id = 'lohronplan-yapp!';
const liveUrl = <a href="https://docs.bebettercity.de/" target="_blank" rel="noopener noreferrer">bebettercity.de</a>;

export class Card extends Component {
  render() {
    return (
      <PortfolioElementCard id={id} name="LohrOnPlan / yapp!" thumbnail={imgThumbnail} thumbnailAlt="LohrOnPlan">
        Your city in one app
      </PortfolioElementCard>
    );
  }
}

const IMAGES = [
  {
    src: imgFullLohronplan,
    caption: 'LohrOnPlan Logo',
  },
  {
    src: imgDetailLohronplanAppHome,
    caption: 'Lohronplan App Home',
  },
  {
    src: imgDetailLohronplanAppProducts,
    caption: 'Lohronplan App Products',
  },
  {
    src: imgDetailLohronplanAppProduct,
    caption: 'Lohronplan App Product',
  },
  {
    src: imgDetailLohronplanAppInformation,
    caption: 'Lohronplan App Information',
  },
  {
    src: imgDetailLohronplanAppNews,
    caption: 'Lohronplan App News',
  },
  {
    src: imgDetailLohronplanAppAccount1,
    caption: 'Lohronplan App Account',
  },
  {
    src: imgDetailLohronplanAppAccount2,
    caption: 'Lohronplan App Account',
  },
  {
    src: imgDetailLohronplanPortalDashboard,
    caption: 'Lohronplan Business Portal Dashboard',
  },
  {
    src: imgDetailLohronplanPortaProduct,
    caption: 'Lohronplan Business Portal Product',
  },
  {
    src: imgDetailLohronplanPortalInformation,
    caption: 'Lohronplan Business Portal Information',
  },
  {
    src: imgDetailLohronplanDocumentation,
    caption: 'Lohronplan Documentation',
  },
  {
    src: imgDetailLohronplanApi,
    caption: 'Lohronplan API',
  },
  {
    src: imgDetailYappAppCities,
    caption: 'yapp! App Multiple City Support',
  },
  {
    src: imgDetailYappAppFaqs,
    caption: 'yapp! App FAQs',
  },
  {
    src: imgDetailYappAppAccount,
    caption: 'yapp! App redesigned Account page',
  },
  {
    src: imgDetailYappPortalAccount,
    caption: 'yapp! Business Portal Account',
  },
  {
    src: imgDetailYappPortalNewsintegration,
    caption: 'yapp! Business Portal News Integration',
  },
  {
    src: imgDetailYappPortalEventintegration,
    caption: 'yapp! Business Portal Event Integration',
  },
  {
    src: imgDetailYappPortalFaqs,
    caption: 'yapp! Business Portal FAQs',
  },
  {
    src: imgDetailYappPortalRolemanagement,
    caption: 'yapp! Business Portal Role Management',
  },
];

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.setPhotoIndex = this.setPhotoIndex.bind(this);

    this.state = {
      photoIndex: false,
    };
  }

  setPhotoIndex(index) {
    this.setState({photoIndex: index});
  }

  render() {
    return (
      <PortfolioElementModal id={id} name="LohrOnPlan / yapp!" description="Your city in one app">
        <Lightbox images={IMAGES} photoIndex={this.state.photoIndex} onPhotoIndexChange={this.setPhotoIndex} />

        <p>This project is available on {liveUrl}!</p>

        <p>
          At <a href="https://idis.fhws.de" target="_blank" rel="noopener noreferrer">Institute for Design and Information Systems</a>, I started the project and developed a solution called LohrOnPlan for the city of Lohr.
          After the project finished it was handed over to <a href="https://cherry-click.de" target="_blank" rel="noopener noreferrer">cherry-click GmbH</a>. There I turned the plattform into a solution for multiple cities and thus made it suitable for mass usage under the new name yapp!.
        </p>

        <h3>LohrOnPlan</h3>
        <img className="img-fluid border img-clickable" src={imgFullLohronplan} alt="LohrOnPlan" onClick={() => this.setPhotoIndex(0)}/>
        <p>
        LohrOnPlan was launched as described <a href="https://fiw.fhws.de/forschung/projekte/lohr-onplan/" target="_blank" rel="noopener noreferrer">here</a> with the aim of creating a platform where citizens, retailers, service providers, restaurateurs and the city administration can interact with each other.
        In this project I was responsible for the complete development.
        I developed two frontends, one of which is built into a native app using <a href="https://ionicframework.com" target="_blank" rel="noopener noreferrer">Ionic</a>, and a backend with a rest API.
        In addition, I took care of the deployment via <a href='https://www.docker.com' target="_blank" rel="noopener noreferrer">Docker</a> and <a href="https://k8s.io/" target="_blank" rel="noopener noreferrer">Kubernetes</a> as well as the corresponding automation.
        </p>
        <h4>Screenshots</h4>
        <div className="row justify-content-md-center">
          <div className="col col-sm-6 col-md-3">
            <img className="img-fluid border img-clickable" src={imgDetailLohronplanAppHome} alt="Lohronplan App Home" onClick={() => this.setPhotoIndex(1)} />
          </div>
          <div className="col col-sm-6 col-md-3">
            <img className="img-fluid border img-clickable" src={imgDetailLohronplanAppProducts} alt="Lohronplan App Products" onClick={() => this.setPhotoIndex(2)} />
          </div>
          <div className="col col-sm-6 col-md-3">
            <img className="img-fluid border img-clickable" src={imgDetailLohronplanAppProduct} alt="Lohronplan App Product" onClick={() => this.setPhotoIndex(3)} />
          </div>
          <div className="col col-sm-6 col-md-3">
            <img className="img-fluid border img-clickable" src={imgDetailLohronplanAppInformation} alt="Lohronplan App Information" onClick={() => this.setPhotoIndex(4)} />
          </div>
          <div className="col col-sm-6 col-md-3">
            <img className="img-fluid border img-clickable" src={imgDetailLohronplanAppNews} alt="Lohronplan App News" onClick={() => this.setPhotoIndex(5)} />
          </div>
          <div className="col col-sm-6 col-md-3">
            <img className="img-fluid border img-clickable" src={imgDetailLohronplanAppAccount1} alt="Lohronplan App Account" onClick={() => this.setPhotoIndex(6)} />
          </div>
          <div className="col col-sm-6 col-md-3">
            <img className="img-fluid border img-clickable" src={imgDetailLohronplanAppAccount2} alt="Lohronplan App Account" onClick={() => this.setPhotoIndex(7)} />
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col col-sm-4">
            <img className="img-fluid border img-clickable" src={imgDetailLohronplanPortalDashboard} alt="Lohronplan Business Portal Dashboard" onClick={() => this.setPhotoIndex(8)} />
          </div>
          <div className="col col-sm-4">
            <img className="img-fluid border img-clickable" src={imgDetailLohronplanPortaProduct} alt="Lohronplan Business Portal Product" onClick={() => this.setPhotoIndex(9)} />
          </div>
          <div className="col col-sm-4">
            <img className="img-fluid border img-clickable" src={imgDetailLohronplanPortalInformation} alt="Lohronplan Business Portal Information" onClick={() => this.setPhotoIndex(10)} />
          </div>
          <div className="col col-sm-4">
            <img className="img-fluid border img-clickable" src={imgDetailLohronplanDocumentation} alt="Lohronplan Documentation" onClick={() => this.setPhotoIndex(11)} />
          </div>
          <div className="col col-sm-4">
            <img className="img-fluid border img-clickable" src={imgDetailLohronplanApi} alt="Lohronplan API" onClick={() => this.setPhotoIndex(12)} />
          </div>
        </div>

        <h3>yapp!</h3>
        <p>
        LohrOnPlan was handed over to the startup <a href="https://cherry-click.de" target="_blank" rel="noopener noreferrer">cherry-click</a> at the end of the project period and was operated there.
        I moved with the project to the startup and made some more changes like adjustments of the color scheme and the name.
        In addition, I migrated the Git project and hosting to other platforms.
        Besides that I integrated external providers like <a href="https://www.cleverreach.com" target="_blank" rel="noopener noreferrer">CleverReach</a> or the <a href="https://www.lohr.de/tourismus-und-kultur/planen-buchen/veranstaltungen/veranstaltungskalender" target="_blank" rel="noopener noreferrer">event calendar Lohr</a> into the platform.
        I also added support for multiple cities and completely reworked the role concept.
        </p>

        <h4>Screenshots</h4>
        <div className="row justify-content-md-center">
          <div className="col col-sm-6 col-md-3">
            <img className="img-fluid border img-clickable" src={imgDetailYappAppCities} alt="yapp! App Multiple City Support" onClick={() => this.setPhotoIndex(13)} />
          </div>
          <div className="col col-sm-6 col-md-3">
            <img className="img-fluid border img-clickable" src={imgDetailYappAppFaqs} alt="yapp! App FAQs" onClick={() => this.setPhotoIndex(14)} />
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col col-sm-6 col-md-4">
            <img className="img-fluid border img-clickable" src={imgDetailYappAppAccount} alt="yapp! App redesigned Account page" onClick={() => this.setPhotoIndex(15)} />
          </div>
          <div className="col col-sm-6 col-md-4">
            <img className="img-fluid border img-clickable" src={imgDetailYappPortalAccount} alt="yapp! Business Portal Account" onClick={() => this.setPhotoIndex(16)} />
          </div>
          <div className="col col-sm-6 col-md-4">
            <img className="img-fluid border img-clickable" src={imgDetailYappPortalNewsintegration} alt="yapp! Business Portal News Integration" onClick={() => this.setPhotoIndex(17)} />
          </div>
          <div className="col col-sm-6 col-md-4">
            <img className="img-fluid border img-clickable" src={imgDetailYappPortalEventintegration} alt="yapp! Business Portal Event Integration" onClick={() => this.setPhotoIndex(18)} />
          </div>
          <div className="col col-sm-6 col-md-4">
            <img className="img-fluid border img-clickable" src={imgDetailYappPortalFaqs} alt="yapp! Business Portal FAQs" onClick={() => this.setPhotoIndex(19)} />
          </div>
          <div className="col col-sm-6 col-md-4">
            <img className="img-fluid border img-clickable" src={imgDetailYappPortalRolemanagement} alt="yapp! Business Portal Role Management" onClick={() => this.setPhotoIndex(20)} />
          </div>
        </div>

        <FactList>
          <FactListItem>Development: October 2019 - August 2021</FactListItem>
          <FactListItem>Clients: <a href="https://idis.fhws.de" target="_blank" rel="noopener noreferrer">Institute for Design and Information Systems</a>, <a href="https://www.lohr.de" target="_blank" rel="noopener noreferrer">City Lohr</a>, <a href="https://cherry-click.de" target="_blank" rel="noopener noreferrer">cherry-click GmbH</a></FactListItem>
          <FactListItem>Category: Software Development, Project Management</FactListItem>
          <FactListItem>Currently live: {liveUrl}</FactListItem>
          <FactListItem>Source code: <a href="https://git.fiw.fhws.de/lohronplan" target="_blank" rel="noopener noreferrer">LohrOnPlan Gitlab Group</a></FactListItem>
        </FactList>
      </PortfolioElementModal >
    );
  }
}

export default {Card, Modal};


import React, {Component} from 'react';
import {Container, Row} from 'reactstrap';

export default class PublicationsContainer extends Component {
  render() {
    return (
      <Container>
        <Row>
          {this.props.children}
        </Row>
      </Container>
    );
  }
}

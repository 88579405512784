import React, {Component} from 'react';
import {Timeline, TimelineElement, TimelineElementImage, TimelineElementPanel} from '../Components/Timeline/Timeline';

import img1 from './Images/1.jpg';
import img2 from './Images/2.jpg';
import img3 from './Images/3.jpg';
import img4 from './Images/4.jpg';
import img5 from './Images/5.jpg';
import img6 from './Images/6.jpg';
import img7 from './Images/7.jpg';
import img8 from './Images/8.jpg';
import img9 from './Images/9.jpg';
import img10 from './Images/10.jpg';

export default class Work extends Component {
  render() {
    return (
      <Timeline>
        <TimelineElement inverted={false} href="https://union-investment.de/">
          <TimelineElementImage src={img10} alt="Union Investment" />
          <TimelineElementPanel time="Since September 2022" name="Junior IT-Infrastructure Manager at Union Investment">
            <p className="text-muted">
              After my trainee program, I joined Union IT Services GmbH as Junior Cloud-Infrastructure Manager.
              In this position, I am responsible for the cloud core infrastructure, consulting and development.
            </p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://union-investment.de/">
          <TimelineElementImage src={img10} alt="Union Investment" />
          <TimelineElementPanel time="September 2021 - August 2022" name="Information Technology Trainee at Union Investment">
            <p className="text-muted">As part of the trainee program at Union Investment, I worked in a wide variety of departments within the company.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="https://cherry-click.de/">
          <TimelineElementImage src={img9} alt="cherry-click" />
          <TimelineElementPanel time="May - August 2021" name="Developer at cherry-click">
            <p className="text-muted">At cherry-click I continued working on the LohrOnPlan project, which is now called <a href="http://bebettercity.de" target="_blank" rel="noopener noreferrer">yapp!</a>. I added new features to the project and prepared the go-live.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="http://idis.fhws.de">
          <TimelineElementImage src={img8} alt="FHWS" />
          <TimelineElementPanel time="October 2019 - April 2021" name="Developer: IDIS (FHWS)">
            <p className="text-muted">I was working for the Institute Of Design And Information Systems (IDIS) of the University Of Applied Sciences Würzburg-Schweinfurt (FHWS) on a project called LohrOnPlan.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="#portfolio/offer-builder">
          <TimelineElementImage src={img7} alt="FHWS" />
          <TimelineElementPanel time="March 2019 - June 2020" name="Freelancer: Software consulting & development">
            <p className="text-muted">As a freelancer, I developed a web application for a local car dealer to generate offers for multiple platforms.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://sap.com">
          <TimelineElementImage src={img6} alt="SAP SE" />
          <TimelineElementPanel time="March - September 2019" name={'Woking Student: SAP SE'}>
            <p className="text-muted">I changed the department at SAP by continuing some of the previouse tasks.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="https://sap.com">
          <TimelineElementImage src={img6} alt="SAP SE" />
          <TimelineElementPanel time="March 2018 - March 2019" name={'Woking Student: SAP SE'}>
            <p className="text-muted">After my internship at SAP I continued working in the same team and on identical projects as a working student.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://sap.com">
          <TimelineElementImage src={img6} alt="SAP SE" />
          <TimelineElementPanel time="September 2017 - February 2018" name={'Internship: SAP SE'}>
            <p className="text-muted">As part of my practial semester of my university I did an internship at SAP. There I worked in a team of students developing software mainly in SAPUi5 and XSJS (mostly JavaScript).</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="https://solvatio.com">
          <TimelineElementImage src={img5} alt="Solvatio AG" />
          <TimelineElementPanel time="November 2016 - July 2017" name={'Solvatio AG'}>
            <p className="text-muted">During my studies I worked as software tester at Solvatio, to write automated test.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="http://www.dpdhl-jobs-vor-ort.de/de/niederlassungen/brief-wuerzburg.html">
          <TimelineElementImage src={img3} alt="Deutsche Post" />
          <TimelineElementPanel time="August - September 2016" name={'German Postal Serice'}>
            <p className="text-muted">In my semester break I was working at the Deutsche Post.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="https://www.handy-games.com">
          <TimelineElementImage src={img4} alt="HandyGames" />
          <TimelineElementPanel time="May - October 2016" name={'HandyGames'}>
            <p className="text-muted">During my studies I worked as game tester at HandyGames.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="http://www.dpdhl-jobs-vor-ort.de/de/niederlassungen/brief-wuerzburg.html">
          <TimelineElementImage src={img3} alt="Deutsche Post" />
          <TimelineElementPanel time="June - July 2015" name={'German Postal Serice'}>
            <p className="text-muted">I was working at the Deutsche Post after my High School Graduation (Abitur).</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="http://www.networldsystems.de">
          <TimelineElementImage src={img2} alt="Net World Systems" />
          <TimelineElementPanel time="Mai 2012" name={'Internship:\nNet World Systems'}>
            <p className="text-muted">During this internship I learned to solve soft- & hardware problems of computers.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://www.rz.uni-wuerzburg.de">
          <TimelineElementImage src={img1} alt="Julius-Maximilians-University of Würzburg" />
          <TimelineElementPanel time="Mai 2012" name={'Internship: Data Center University of Würzburg'}>
            <p className="text-muted">During my internship at the Data Center of the Julius-Maximilians-University of Würzburg I was taken to every department of the data center and learned different things like, dealing with linux servers, administrating the website, maintaining the network infrastructure and more.</p>
          </TimelineElementPanel>
        </TimelineElement>
      </Timeline>
    );
  }
}

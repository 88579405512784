import React, {Component} from 'react';
import {PortfolioElementCard, PortfolioElementModal} from '../PortfolioElement/PortfolioElement';
import {FactList, FactListItem} from '../PortfolioElement/FactList';
import Lightbox from '../../../../components/Lighbox';
import imgThumbnail from './thumbnail.png';
import imgFull from './full.png';
import imgDetailNew from './detail-new.png';
import imgDetailSolveTask from './detail-solve-task.png';
import imgDetailTaskSolved from './detail-task-solved.png';
import imgDetailPantomime from './detail-pantomime.png';
import imgDetailDirections from './detail-directions.png';
import imgDetailAudio from './detail-audio.png';
import imgDetailFinal from './detail-final.png';

const id = 'wiwo-rally';

export class Card extends Component {
  render() {
    return (
      <PortfolioElementCard id={id} name="WiWo Rally" thumbnail={imgThumbnail} thumbnailAlt="WiWo Rally">
        A digital app for the rally
      </PortfolioElementCard>
    );
  }
}

const IMAGES = [
  {
    src: imgFull,
    caption: 'Welcome Screen',
  },
  {
    src: imgDetailNew,
    caption: 'Start the rally',
  },
  {
    src: imgDetailSolveTask,
    caption: 'Task',
  },
  {
    src: imgDetailTaskSolved,
    caption: 'Solved Task',
  },
  {
    src: imgDetailPantomime,
    caption: 'Pantomime task',
  },
  {
    src: imgDetailDirections,
    caption: 'Directions',
  },
  {
    src: imgDetailAudio,
    caption: 'Task with Audio',
  },
  {
    src: imgDetailFinal,
    caption: 'Final station',
  },
];

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.setPhotoIndex = this.setPhotoIndex.bind(this);

    this.state = {
      photoIndex: false,
    };
  }

  setPhotoIndex(index) {
    this.setState({photoIndex: index});
  }

  render() {
    return (
      <PortfolioElementModal id={id} name="WiWo Rally" description="A digital app for the rally">
        <Lightbox images={IMAGES} photoIndex={this.state.photoIndex} onPhotoIndexChange={this.setPhotoIndex} />
        <img className="img-fluid border img-clickable" src={imgFull} alt="WiWo Rally" onClick={() => this.setPhotoIndex(0)} style={{maxWidth: '75vh', maxHeight: '75vh'}} />

        <p>
          Normally the acolytes of St. Lioba spend a weekend together in winter.
          However, since this is not possible because of Corona, we have unceremoniously constructed a rally.
        </p>

        <p>
          You have to open the webapp once, then it is also available offline.
          Now you can walk through the stations and, among other things, solve various tasks and play games.
        </p>

        <h3>Screenshots</h3>
        <div className="row justify-content-md-center">
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailNew} alt="Start the rally" onClick={() => this.setPhotoIndex(1)} />
          </div>
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailSolveTask} alt="Task" onClick={() => this.setPhotoIndex(2)} />
          </div>
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailTaskSolved} alt="Solved Task" onClick={() => this.setPhotoIndex(3)} />
          </div>
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailPantomime} alt="Pantomime task" onClick={() => this.setPhotoIndex(4)} />
          </div>
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailDirections} alt="Directions" onClick={() => this.setPhotoIndex(5)} />
          </div>
          <div className="col col-md-3 col-sm-6">
            <img className="img-fluid border img-clickable" src={imgDetailAudio} alt="Task with Audio" onClick={() => this.setPhotoIndex(6)} />
          </div>
          <div className="col col-md-3 col-sm-66">
            <img className="img-fluid border img-clickable" src={imgDetailFinal} alt="Final station" onClick={() => this.setPhotoIndex(7)} />
          </div>
        </div>

        <FactList>
          <FactListItem>Co-workers: Mirjam, Hanna & Thomas</FactListItem>
          <FactListItem>Development: December 2020 - January 2021</FactListItem>
          <FactListItem>Client: <a href="https://www.minis-lioba.de" target="_blank" rel="noopener noreferrer">Minis St. Lioba</a></FactListItem>
          <FactListItem>Category: Event Planning, Software Developement</FactListItem>
        </FactList>
      </PortfolioElementModal >
    );
  }
}

export default {Card, Modal};


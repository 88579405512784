import React, {Component} from 'react';
import SocialButton from './SocialButton';
import {faLinkedin, faFacebook, faInstagram, faYoutube, faSkype, faSnapchat, faGitlab, faGithub, faResearchgate, faDocker, faNpm, faGooglePlay} from '@fortawesome/free-brands-svg-icons';

export default class Social extends Component {
  render() {
    return (
      <div className="row text-center">
        <SocialButton logo={faLinkedin} heading="LinkedIn" subheading="Contact me on LinkedIn." href="https://www.linkedin.com/in/felix-franz" divClass="col-md-4 col-lg-2" />
        <SocialButton logo={faFacebook} heading="Facebook" subheading="Contact me on Facebook." href="https://www.facebook.com/felix.franz.9250" divClass="col-md-4 col-lg-2" />
        <SocialButton logo={faInstagram} heading="Instagram" subheading="Follow me on Instagram." href="https://www.instagram.com/felixf25" divClass="col-md-4 col-lg-2" />
        <SocialButton logo={faYoutube} heading="YouTube" subheading="See my videos on YouTube." href="https://www.youtube.com/channel/UCCIZ0TNMhdae-EXcQ_IiRjQ" divClass="col-md-4 col-lg-2" />
        <SocialButton logo={faSkype} heading="Skype" subheading="Call or text me on Skype." href="skype:ffelix25?chat" divClass="col-md-4 col-lg-2" />
        <SocialButton logo={faSnapchat} heading="Snapchat" subheading="Snap me on Snapchat." href="https://www.snapchat.com/add/felixfranz25" divClass="col-md-4 col-lg-2" />
        <SocialButton logo={faGitlab} heading="Gitlab" subheading="Explore my projects on Gitlab." href="https://gitlab.com/Felix-Franz" divClass="col-md-4 col-lg-2" />
        <SocialButton logo={faGithub} heading="Github" subheading="Explore my projects on Github." href="https://github.com/Felix-Franz" divClass="col-md-4 col-lg-2" />
        <SocialButton logo={faDocker} heading="Docker" subheading="Explore my Docker images." href="https://hub.docker.com/u/felixfranz/" divClass="col-md-4 col-lg-2" />
        <SocialButton logo={faNpm} heading="NPM" subheading="Explore my NPM packages." href="https://www.npmjs.com/~felix-franz/" divClass="col-md-4 col-lg-2" />
        <SocialButton logo={faGooglePlay} heading="Google Play" subheading="Discover my apps." href="https://play.google.com/store/apps/dev?id=8764069089260729342" divClass="col-md-4 col-lg-2" />
        <SocialButton logo={faResearchgate} heading="ResearchGate" subheading="View my publications." href="https://www.researchgate.net/profile/Felix_Franz" divClass="col-md-4 col-lg-2" />
      </div>

    );
  }
}

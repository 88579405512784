import React, {Component} from 'react';

export default class TimelineElementImage extends Component {
  render() {
    return (
      <div className="timeline-image">
        <img className="rounded-circle img-fluid" src={this.props.src} alt={this.props.alt} />
      </div>
    );
  }
}

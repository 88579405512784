import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons';

export default class PublicationItem extends Component {
  render() {
    return (
      <Col xl={4} md={6} sm={12} className="publication-item">
        <div className="publication-content bg-light">
          <a className="publication-link" href={this.props.link} target="_blank" rel="noopener noreferrer">
            <div className="publication-hover" />
          </a>
          <Container>
            <Row>
              <Col sm={12}>
                <span className="fa-stack fa-3x">
                  <FontAwesomeIcon icon={faCircle} className="fa-stack-2x text-primary"/>
                  <FontAwesomeIcon icon={this.props.icon} className="fa-stack-1x fa-inverse"/>
                </span>
                <p className="text-muted">{this.props.type}</p>
              </Col>
              <Col sm={12}>
                <h4>{this.props.title}</h4>
                {this.props.children}
              </Col>
            </Row>
          </Container>
        </div>
      </Col>
    );
  }
}

import React, {Component} from 'react';
import {PortfolioElementCard, PortfolioElementModal} from '../PortfolioElement/PortfolioElement';
import {FactList, FactListItem} from '../PortfolioElement/FactList';
import Lightbox from '../../../../components/Lighbox';
import imgThumbnail from './thumbnail.png';
import imgFull from './full.png';

const id = 'express-requestprocessor';

export class Card extends Component {
  render() {
    return (
      <PortfolioElementCard id={id} name="Express RequestProcessor" thumbnail={imgThumbnail} thumbnailAlt="Express RequestProcessor">
        RequestProcessor combines your services into an api using <a href="https://expressjs.com/" target="_blank" rel="noopener noreferrer">Express</a>
      </PortfolioElementCard>
    );
  }
}

const IMAGES = [
  {
    src: imgFull,
    caption: 'Express RequestProcessor',
  },
];

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.setPhotoIndex = this.setPhotoIndex.bind(this);

    this.state = {
      photoIndex: false,
    };
  }

  setPhotoIndex(index) {
    this.setState({photoIndex: index});
  }

  render() {
    return (
      <PortfolioElementModal id={id} name="Express RequestProcessor" description="RequestProcessor combines your services into an api using Express">
        <Lightbox images={IMAGES} photoIndex={this.state.photoIndex} onPhotoIndexChange={this.setPhotoIndex} />
        <img className="img-fluid border img-clickable" src={imgFull} alt="Express RequestProcessor" onClick={() => this.setPhotoIndex(0)} />

        <p>
              This project is available on <a href="https://gitlab.com/FelixFranz/express-requestprocessor" target="_blank" rel="noopener noreferrer">Gitlab</a> and <a href="https://www.npmjs.com/package/express-requestprocessor" target="_blank" rel="noopener noreferrer">npm</a>!
        </p>

        <p>
            For the <a href={`${process.env.PUBLIC_URL}/identity#portfolio/4`} target="_blank" rel="noopener noreferrer">Minis St. Lioba Webapp Project</a> I built a library for the backend to assemble the api.
            Since I wanted to reuse it in a later project, I published the extracted library on npm.
            I have improved the project more and more since then and added e.g. a typescript compatibility or a automatically swagger docs generation.
        </p>

        <p>
              If you plan to build an API, I would be happy if you would use my npm package.
              A short introduction can be found in the <a href="https://www.npmjs.com/package/express-requestprocessor?activeTab=readme" target="_blank" rel="noopener noreferrer">npm module</a>.
              If you have any questions or are missing something, just <a href="https://gitlab.com/FelixFranz/express-requestprocessor/-/issues/new" target="_blank" rel="noopener noreferrer">open an issue</a>.
        </p>

        <FactList>
          <FactListItem>Development: Since April 2019</FactListItem>
          <FactListItem>Client: Public</FactListItem>
          <FactListItem>Category: Software Developement</FactListItem>
          <FactListItem>External information: <a href="https://gitlab.com/FelixFranz/express-requestprocessor" target="_blank" rel="noopener noreferrer">Gitlab</a> & <a href="https://www.npmjs.com/package/express-requestprocessor" target="_blank" rel="noopener noreferrer">npm</a></FactListItem>
        </FactList>
      </PortfolioElementModal >
    );
  }
}

export default {Card, Modal};

import React, {Component} from 'react';
import NavBar from '../../components/NavBar';
import Content from './Content';

export default class About extends Component {
  componentWillMount() {
    document.getElementsByTagName('HTML')[0].lang='de';
  }

  componentWillUnmount() {
    document.getElementsByTagName('HTML')[0].lang='en';
  }

  render() {
    return (
      <div>
        <NavBar title='Datenschutzerklärung / Privacy policy' />
        <Content />
      </div>
    );
  }
}

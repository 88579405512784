import React, {Component} from 'react';
import {PortfolioElementCard, PortfolioElementModal} from '../PortfolioElement/PortfolioElement';
import {FactList, FactListItem} from '../PortfolioElement/FactList';
import Lightbox from '../../../../components/Lighbox';
import imgThumbnail from './thumbnail.jpg';
import imgLoading from './detail-loading.jpg';
import imgMain from './main.jpg';

const id='vlccontroller';

export class Card extends Component {
  render() {
    return (
      <PortfolioElementCard id={id} name="VLCController" thumbnail={imgThumbnail} thumbnailAlt="VLCController">
        A Software to controle multiple instances of VLC Media Player
      </PortfolioElementCard>
    );
  }
}

const IMAGES = [{src: imgLoading, caption: 'Loading screen'}, {src: imgMain, caption: 'Main screen with device list and control buttons'}];

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.setPhotoIndex = this.setPhotoIndex.bind(this);

    this.state = {
      photoIndex: false,
    };
  }

  setPhotoIndex(index) {
    this.setState({photoIndex: index});
  }

  render() {
    return (
      <PortfolioElementModal id={id} name="VLCController" description="A Software to controle multiple instances of VLC Media Player">
        <Lightbox images={IMAGES} photoIndex={this.state.photoIndex} onPhotoIndexChange={this.setPhotoIndex}/>
        <div className="row justify-content-md-center">
          <div className="col col-md-6 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgLoading} alt="Loading screen" onClick={() => this.setPhotoIndex(0)} />
          </div>
          <div className="col col-md-6 col-sm-6 col-6">
            <Lightbox images={[imgMain, imgLoading]} />
            <img className="img-fluid border img-clickable" src={imgMain} alt="Main screen with device list and control buttons" onClick={() => this.setPhotoIndex(1)}/>
          </div>
        </div>

        <p>
          You can use this software for free! <a href="https://github.com/Felix-Franz/VLCController/wiki" target="_blank" rel="noopener noreferrer">
            Here are download and install instructions</a>.
        </p>
        <p>
          In September 2016 <a href="http://timofischerprojects.com" target="_blank" rel="noopener noreferrer">Timo Fischer</a> asked me about a solution to control
          multiple instances of VLC Media Player simultanious for his <a href="https://youtu.be/k-Vf8D-GuwM" target="_blank" rel="noopener noreferrer">
            halloween project</a>. Therefore I developed VLCController 1.0.
          <br />
          One year later I did a compleate re-implementation of this software, to run commands faster, use other (better) techniques and
          control the software on mutiple devices simultaniouse (because I made a webapp of it). I also added new features. So VLCController 2.0
          was born.
        </p>
        <p>This Project is available on <a href="https://github.com/Felix-Franz/VLCController" target="_blank" rel="noopener noreferrer">Github</a>.</p>

        <FactList>
          <FactListItem>Release: October 2017</FactListItem>
          <FactListItem>Client: <a href="http://timofischerprojects.com" target="_blank" rel="noopener noreferrer">Timo Fischer</a> & Public</FactListItem>
          <FactListItem>Category: Software Developement</FactListItem>
          <FactListItem>External information: <a href="https://github.com/Felix-Franz/VLCController" target="_blank" rel="noopener noreferrer">Github</a></FactListItem>
        </FactList>
      </PortfolioElementModal>
    );
  }
}

export default {Card, Modal};

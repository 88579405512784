import React, {Component} from 'react';
import {PortfolioElementCard, PortfolioElementModal} from '../PortfolioElement/PortfolioElement';
import {FactList, FactListItem} from '../PortfolioElement/FactList';
import Lightbox from '../../../../components/Lighbox';
import imgThumbnail from './thumbnail.jpg';
import imgFull from './full.jpg';

const id='peng';

export class Card extends Component {
  render() {
    return (
      <PortfolioElementCard id={id} name="PENG!" thumbnail={imgThumbnail} thumbnailAlt="PENG! Logo">
                Physic science show
      </PortfolioElementCard>
    );
  }
}

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.setPhotoIndex = this.setPhotoIndex.bind(this);

    this.state = {
      photoIndex: false,
    };
  }

  setPhotoIndex(index) {
    this.setState({photoIndex: index});
  }

  render() {
    return (
      <PortfolioElementModal id={id} name="PENG!" description="Creating and running a physic science show">
        <Lightbox images={[{src: imgFull, caption: 'PENG! Logo'}]} photoIndex={this.state.photoIndex} onPhotoIndexChange={this.setPhotoIndex} />
        <img className="img-fluid border img-clickable" src={imgFull} alt="PENG! Logo" onClick={() => this.setPhotoIndex(0)} />

        <p>
                    During my P-Seminar, a subject at school where pupils should plan and run a project by their own, I created, as a team of about 10 pupils, a Physic Show.
          <br /> We hat many different tasks to do, for examle finding and running experiments, doing marketing, decorating, planning the speaches of the performers, doing technical steage work, ...
          <br /><br /> One of my parts was to find and plan some physic experiments for the show, I let the Newtonian fluid dance and shattered a sparking wine glass - just with sound.
          <br /> I was mainly responsible for the stage lights, music and sound effects, amplifying the performers, showing videos and displaing a live stream on a big screen (for smaller experiments).
          <br /> It was also my part to do the marketing. Therefore we contacted the local <a href="http://www.radiogong.com" target="_blank" rel="noopener noreferrer">radio station</a>, published a <a href="https://youtu.be/xPxcKOJP8PI" target="_blank" rel="noopener noreferrer">trailer on Youtube</a>, built a <a href="http://peng.evdhg.de/" target="_blank" rel="noopener noreferrer">website</a> and created a <a href="https://www.facebook.com/PENG.Physik/" target="_blank" rel="noopener noreferrer">Facebook page</a>.
          <br /> And additionally, I did a lot of minor stuff to help the project to take place and become a success.
          <br /><br /> The project was very successful and just with donations, we got 650€ that we <a href="https://www.mainpost.de/regional/wuerzburg/Schueler-spenden-fuer-Bahnhofsmission;art736,8937423,B::pic17683,6803853" target="_blank" rel="noopener noreferrer">donated</a> to the <a href="http://www.bahnhofsmission-wuerzburg.de" target="_blank" rel="noopener noreferrer">Bahnhofsmission Würzburg</a>.
        </p>

        <FactList>
          <FactListItem>Co-workers: My P-Seminar Class</FactListItem>
          <FactListItem>Performance date: 11.01.2015</FactListItem>
          <FactListItem>Client: <a href="http://evdhg.de/" target="_blank" rel="noopener noreferrer">School</a></FactListItem>
          <FactListItem>Category: Project Planning</FactListItem>
        </FactList>
      </PortfolioElementModal>
    );
  }
}

export default {Card, Modal};

import React, {Component} from 'react';
import {Button, Input, InputGroup, InputGroupAddon, Form} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLockOpen, faPaperPlane} from '@fortawesome/free-solid-svg-icons';

export default class TokenPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('token', this.state.token);
    window.location.search = searchParams.toString();
  }

  render() {
    return (
      <div className='text-center'>
        <p>
          This section is hidden for privacy reasons.
          If you want to have access to the content of the section, feel free to request it at any time.
          Then use the link sent to you to see all the sections or input the token below.
          If this message is then still shown, your token is probably invalid.
        </p>
        <Button color="primary" outline size="lg" href={`${process.env.PUBLIC_URL}/identity?&subject=Request website access&message=Hallo Felix,%0D%0A%0D%0AI'd like to request access to all parts of your website (${window.location.origin}${window.location.pathname}), because <ADD REASON HERE>.%0D%0A%0D%0ASincerely%0D%0A<ADD YOUR NAME HERE>#contact`}>
          <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />Request Access
        </Button>
        <Form onSubmit={this.handleSubmit} acceptCharset="utf-8" className="mt-3">
          <InputGroup className="m-auto" style={{maxWidth: '30em'}}>
            <Input name="token" type="text" placeholder="Insert token here" className="text-center" required value={this.state.token} onChange={(e) => this.setState({token: e.target.value})} />
            <InputGroupAddon addonType="append" >
              <button className="btn btn-outline-primary text-uppercase" type="submit">
                <FontAwesomeIcon icon={faLockOpen} className="mr-2" />Unlock
              </button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      </div>
    );
  }
}

import React, {Component} from 'react';

import 'startbootstrap-agency/css/agency.css';
import './App.css';
import Router from './Router';

export default class App extends Component {
  componentDidMount() {
    window.addEventListener('load', () => {
      document.getElementById('root').style.display = 'initial';
      document.getElementById('loader').style.opacity = 0;
      setTimeout(() => document.getElementById('loader').style.display = 'none', 1000);
    });
  }

  render() {
    return (
      <Router/>
    );
  }
}

import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCertificate, faCircle} from '@fortawesome/free-solid-svg-icons';

export default class CertificateItem extends Component {
  render() {
    return (
      <Col xl={4} md={6} sm={12} className="publication-item">
        <div className="publication-content bg-white">
          <a className="publication-link" href={this.props.link} target="_blank" rel="noopener noreferrer">
            <div className="publication-hover" />
          </a>
          <Container>
            <Row>
              <Col sm={12}>
                <span className="fa-stack fa-7x">
                  <FontAwesomeIcon icon={faCircle} className="fa-stack-2x text-primary"/>
                  {
                    this.props.badge ?
                    <img src={this.props.badge} alt="badge" className="w-75 fa-stack-1x"/>
                    :
                  <FontAwesomeIcon icon={this.props.icon || faCertificate} className="fa-stack-1x fa-inverse"/>
                  }
                </span>
                <p className="text-muted">{this.props.subtitle}</p>
              </Col>
              <Col sm={12}>
                <h4>{this.props.title}</h4>
                <p className="text-muted">{this.props.children}</p>
              </Col>
            </Row>
          </Container>
        </div>
      </Col>
    );
  }
}

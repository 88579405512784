import React, {Component} from 'react';
import './Publications.css';
import PublicationItem from './PublicationItem';
import PublicationsContainer from './PublicationsContainer';
import {faFileAlt} from '@fortawesome/free-solid-svg-icons';

export default class Publications extends Component {
  render() {
    return (
      <PublicationsContainer>
        <PublicationItem title="Proactive Smart City Interactions" type="Conference Paper" icon={faFileAlt}
          link="https://doi.org/10.1007/978-3-030-50506-6_42">
          <p className="text-muted">The paper describes how an application for a Smart City Interaction is being developed at the small city of Lohr a.Main in Bavaria Germany.</p>
        </PublicationItem>
        <PublicationItem title="Democratization of Smart Contracts: A Prototype for Automated Contract Generation" type="Conference Paper" icon={faFileAlt}
          link="https://doi.org/10.1109/ICBC48266.2020.9169479">
          <p className="text-muted">This paper answers the question weather it is possible to create Smart Contracts using a generator prototype.</p>
        </PublicationItem>
        <PublicationItem title="Towards Human-readable Smart Contracts" type="Conference Paper" icon={faFileAlt}
          link="https://doi.org/10.1109/BLOC.2019.8751309">
          <p className="text-muted">A paper about how you can enable anybody to create Smart Contracts using
                        generation.</p>
        </PublicationItem>
      </PublicationsContainer>
    );
  }
}

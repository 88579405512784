import React, {Component} from 'react';

class FactList extends Component {
  render() {
    return (
      <ul className="list-inline">
        {this.props.children}
      </ul>
    );
  }
}

class FactListItem extends Component {
  render() {
    return (
      <li>{this.props.children}</li>
    );
  }
}

export {FactList, FactListItem};

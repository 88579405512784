import React, {Component} from 'react';

export default class TimelineElementText extends Component {
  render() {
    return (
      <div className="timeline-image" onClick={this.props.onClick} style={{cursor: this.props.onClick ? 'pointer' : undefined}}>
        <h4>{this.props.children}</h4>
      </div>
    );
  }
}

import React, {Component} from 'react';
import NavBar from './NavBar';
import Head from './Head/Head';
import Social from './Social/Social';
import './Identity.css';
import Section from './Section';
import Work from './Work/Work';
import Education from './Education/Education';
import Portfolio from './Portfolio/Portfolio';
import Contact from './Contact/Contact';
import Publications from './Publications/Publications';
import Token from '../../libraries/Token';
import Certificates from './Certificates/Certificates';
import Volunteering from './Volunteering/Volunteering';

export default class Identity extends Component {
  constructor(props) {
    super(props);
    this.onNavigation = this.onNavigation.bind(this);
    this.onNavigated = this.onNavigated.bind(this);
    this.state = {
      isTokenValid: false,
    };
  }

  componentDidMount() {
    this.setState({isTokenValid: Token.isValid()});
  }

  onNavigation(e) {
    e.preventDefault();
    this.setState({
      isOpen: false,
    });
    const hash = e.target.getAttribute('href');
    document.getElementById(hash.substr(1)).scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  onNavigated(hash) {
    if (window.location.hash.match(/\//)) {
      return;
    }
    if (window.location.hash !== '#' + hash) {
      if (window.history.pushState) {
        window.history.pushState(null, null, '#' + hash);
      } else {
        window.location.hash = '#' + hash;
      }
      document.dispatchEvent(new CustomEvent('navigated', {detail: {hash}}));
    }
  }

  render() {
    return (
      <div>
        <NavBar {...this.props} onNavigation={this.onNavigation} />
        <Head onNavigation={this.onNavigation} onNavigated={this.onNavigated} />
        <Section id="portfolio" heading="Portfolio" subheading="Explore some of my bigger Projects" content={<Portfolio />} onNavigated={this.onNavigated} className="bg-light" />
        <Section id="social" heading="Social" subheading="Find me on the internet, feel free to contact me anywhere you like" content={<Social />} onNavigated={this.onNavigated} />
        <Section id="certificates" heading="Certificates" subheading="Check my certificates" content={<Certificates />} onNavigated={this.onNavigated} className="bg-light" />
        <Section id="publications" heading="Publications" subheading="Explore my publications" content={<Publications />} onNavigated={this.onNavigated} />
        <Section id="work" heading="Work" subheading="My work experience" content={<Work />} onNavigated={this.onNavigated} showPasswordPage={!this.state.isTokenValid} className="bg-light"/>
        <Section id="volunteering" heading="Volunteering" subheading="My voluntary activities" content={<Volunteering />} onNavigated={this.onNavigated} showPasswordPage={!this.state.isTokenValid}/>
        <Section id="education" heading="Education" subheading="School, higher education and more..." content={<Education />} onNavigated={this.onNavigated} showPasswordPage={!this.state.isTokenValid} className="bg-light" />
        <Section id="contact" heading="Contact Me" subheading="I am glad to hear from you" content={<Contact />} onNavigated={this.onNavigated} />
        <div style={{height: '0.1em', backgroundColor: 'white'}} />
      </div>
    );
  }
}

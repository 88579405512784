import React, {Component} from 'react';
import axios from 'axios';
import {Form, FormGroup, Input, Alert} from 'reactstrap';
import './Contact.css';
import QueryString from 'query-string';

const clearState = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: '',
  error: false,
  success: false,
};

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.demissSuccess = this.demissSuccess.bind(this);
    this.demissError = this.demissError.bind(this);

    const query = QueryString.parse(window.location.search);
    const state = clearState;
    ['name', 'mail', 'phone', 'subject', 'message'].forEach((key) => {
      if (query[key]) {
        state[key] = query[key];
      }
    });
    this.state = state;
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = this.state;
    delete data.error;
    delete data.success;
    axios({
      method: 'POST',
      url: '/api/contact.php',
      data: data,
    })
        .then((response) => {
          const state = clearState;
          state.success = true;
          this.setState(state);
        })
        .catch((response) => {
          this.setState({error: true, success: false});
        });
  }

  demissSuccess(e) {
    this.setState({success: false});
  }

  demissError(e) {
    this.setState({error: false});
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <Form onSubmit={this.handleSubmit} acceptCharset="utf-8">
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Input name="name" type="text" placeholder="Your Name *" value={this.state.name} onChange={this.handleChange} required />
                </FormGroup>
                <FormGroup>
                  <Input name="email" type="email" placeholder="Your Email *" value={this.state.email} onChange={this.handleChange} required />
                </FormGroup>
                <FormGroup>
                  <Input name="phone" type="tel" placeholder="Your Phone" value={this.state.phone} onChange={this.handleChange} />
                </FormGroup>
                <FormGroup>
                  <Input name="subject" type="text" placeholder="Subject *" value={this.state.subject} onChange={this.handleChange} required />
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <textarea className="form-control" name="message" placeholder="Your Message *" required onChange={this.handleChange} value={this.state.message} />
                </FormGroup>
              </div>
              <div className="clearfix"></div>
              <div className="col-lg-12 text-center">
                <div style={{height: '70px'}}>
                  <Alert name="success" color="success" isOpen={this.state.success} toggle={this.demissSuccess}>Successfully sent message.</Alert>
                  <Alert name="error" color="danger" isOpen={this.state.error} toggle={this.demissError}>Could not sent message</Alert>
                </div>
                <button id="sendMessageButton" className="btn btn-primary btn-xl text-uppercase" type="submit">Send Message</button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

import React, {Component} from 'react';
import PortfolioPeng from './1-Peng/PortfolioPeng';
import PortfolioJoerke from './2-Joerke/PortfolioJoerke';
import PortfolioVLCController from './3-VLCController/PortfolioVLCController';
import PortfolioMiniWebapp from './4-MiniWebapp/PortfolioMinisWebapp';
import PortfolioSmartContractGenerator from './5-SmartContractGenerator/PortfolioSmartContractGenerator';
import PortfolioCodeForest from './6-CodeForest/PortfolioCodeForest';
import PortfolioOfferBuilder from './7-OfferBuilder/PortfolioOfferBuilder';
import PortfolioExpressRequestProcessor from './8-ExpressRequestprocessor/PortfolioExpressRequestProcessor';
import PortfolioWiWoRally from './9-WiWoRally/PortfolioWiWoRally';
import PortfolioTPot from './10-TPot/PortfolioTPot';
import './Portfolio.css';
import {ITEM_COUNT} from '../../../Constants';
import Collapse from 'reactstrap/lib/Collapse';
import Button from 'reactstrap/lib/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import PortfolioLohrOnPlan from './11-LohrOnPlan/PortfolioLohrOnPlan';

const Portfolios = [PortfolioLohrOnPlan, PortfolioTPot, PortfolioWiWoRally, PortfolioExpressRequestProcessor, PortfolioOfferBuilder, PortfolioCodeForest, PortfolioSmartContractGenerator, PortfolioMiniWebapp, PortfolioVLCController, PortfolioJoerke, PortfolioPeng];

export default class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state= {
      open: false,
    };
  }

  render() {
    const Cards = Portfolios.map((p, i) => <p.Card key={i}/>);
    const Modals = Portfolios.map((p, i) => <p.Modal key={i}/>);
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="row">
              {Cards.slice(0, ITEM_COUNT)}
            </div>
            <Collapse isOpen={this.state.open} className="row">
              {Cards.slice(ITEM_COUNT)}
            </Collapse>
            <div className="m-auto">
              <Button outline color="primary" className="rounded-circle" size="lg" onClick={() => this.setState({open: !this.state.open})}>
                <FontAwesomeIcon icon={this.state.open ? faChevronUp : faChevronDown} />
              </Button>
            </div>
          </div>
        </div>
        <div>
          {Modals}
        </div>
      </div>
    );
  }
}

/* eslint camelcase: "off" */
import React, {Component} from 'react';
import {Timeline, TimelineElement, TimelineElementText, TimelineElementImage, TimelineElementPanel} from '../Components/Timeline/Timeline';
import img1 from './Images/1.jpg';
import img2_1 from './Images/2_1.jpg';
import img2_5 from './Images/2_5.jpg';
import img3 from './Images/3.jpg';
import img3_1 from './Images/3_1.jpg';
import img3_3 from './Images/3_3.jpg';
import img3_4 from './Images/3_4.jpg';
import img3_6 from './Images/3_6.png';
import img4 from './Images/4.jpg';
import img4_1 from './Images/4_1.jpg';
import img5_1 from './Images/5_1.jpg';

export default class Education extends Component {
  render() {
    return (
      <Timeline>
        <TimelineElement inverted={true} href="https://fiw.fhws.de/">
          <TimelineElementImage src={img3} alt="FHWS" />
          <TimelineElementPanel time="July 2021" name={'Master graduation'}>
            <p className="text-muted">I successfully finished my studies at <a href="https://fiw.fhws.de/" target="_blank" rel="noopener noreferrer">FHWS</a> with a "Master of Science".</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://www.hochschul-praxistage.de/">
          <TimelineElementText>Hochschul-Praxistage</TimelineElementText>
          <TimelineElementPanel time="March 2021" name='Hochschul-Praxistage'>
            <p className="text-muted">As part of the Hochschul-Praxistage I visited the companies <a href="https://www.soloplan.de/" target="_blank" rel="noopener noreferrer">Soloplan</a>, <a href="https://www.edr-software.com/" target="_blank" rel="noopener noreferrer">edr Software</a>,  <a href="https://www.jambit.com/" target="_blank" rel="noopener noreferrer">jambit</a> and  <a href="https://www.qaware.de/" target="_blank" rel="noopener noreferrer">quaware</a>. There I got to know the companies and socialized with employees and other students.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://www.sap.com/">
          <TimelineElementImage src={img5_1} alt="SAP FastTrack Program" />
          <TimelineElementPanel time="Mai 2020" name='SAP FastTrack Program'>
            <p className="text-muted">As one of the top ten percent highest performing students at SAP in the Germany, Austria and Swizerland region I joined the SAP FastTrack Program.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://www.hochschul-praxistage.de/">
          <TimelineElementText>Hochschul-Praxistage</TimelineElementText>
          <TimelineElementPanel time="March 2020" name='Hochschul-Praxistage'>
            <p className="text-muted">As part of the Hochschul-Praxistage I visited the companies <a href="https://www.hetzner.de/" target="_blank" rel="noopener noreferrer">Hetzner Online</a> and <a href="https://www.munich-airport.de/" target="_blank" rel="noopener noreferrer">Flughafen München GmbH</a>. I not only gained an insight into the working life of these companies, but was also able to make contacts.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://bixlab.fhws.de/?page_id=333">
          <TimelineElementText>FWPM IT Consulting</TimelineElementText>
          <TimelineElementPanel time="October 2019 - Feb. 2020" name='FWPM IT Consulting'>
            <p className="text-muted">During the course IT Consulting I got to know the activities of a consultant. Through presentations and workshops of the companies <a href="https://www.salt-solutions.de/" target="_blank" rel="noopener noreferrer">SALT Solutions</a>, <a href="https://www.t-systems.com/" target="_blank" rel="noopener noreferrer">T-Systems</a>, <a href="https://www.capgemini.com/" target="_blank" rel="noopener noreferrer">Capgemini</a>, <a href="https://www.deloitte.com/" target="_blank" rel="noopener noreferrer">Deloit</a>, <a href="https://www.mhp.com/" target="_blank" rel="noopener noreferrer">MHP</a> & <a href="https://vintin.de/" target="_blank" rel="noopener noreferrer">Vintin</a>,
                      I could gain additional practical experience.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="https://fiw.fhws.de">
          <TimelineElementImage src={img3} alt="University of Applied Sciences Würzburg-Schweinfurt" />
          <TimelineElementPanel time="October 2019 - September. 2021" name={'Master:\nUniversity of Applied Sciences\nWürzburg-Schweinfurt'}>
            <p className="text-muted">I am continuing my Master in Computer Science at University of Applied Sciences Würzburg-Schweinfurt.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://gitlab.com/msp2019">
          <TimelineElementImage src={img4_1} alt="Code Forest" />
          <TimelineElementPanel time="May - June 2019" name={'Code Forest'}>
            <p className="text-muted">As part of the Master Software Project of <a href="https://www.informatik-master.hs-mannheim.de/" target="_blank" rel="noopener noreferrer">University of Applied Sciences Mannheim</a> we created a visualization solution that allows people without technical background to understand different code quality metrics.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="https://www.informatik-master.hs-mannheim.de/">
          <TimelineElementImage src={img4} alt="Hochschule Mannheim – University of Applied Sciences" />
          <TimelineElementPanel time="March - August 2019" name={'Master:\nHochschule Mannheim\nUniversity of Applied Sciences'}>
            <p className="text-muted">I started my master in Computer Science with the focus on Software Engineering at Master level at the University of Applied Sciences Mannheim.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://fiw.fhws.de/">
          <TimelineElementImage src={img3} alt="FHWS" />
          <TimelineElementPanel time="February 2019" name={'Bachelor graduation'}>
            <p className="text-muted">I successfully finished my studies at <a href="https://fiw.fhws.de/" target="_blank" rel="noopener noreferrer">FHWS</a> with a "Bachelor of Engineering".</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://gitlab.com/FelixFranz/FHWS/smart-contract-generation/generator">
          <TimelineElementImage src={img3_6} alt="Smart Contract Generator" />
          <TimelineElementPanel time="October 2018 - Feb. 2019" name={'Bachelor Thesis:\nSmart Contract Generator'}>
            <p className="text-muted">As part of my bachelor thesis I created a template based generator for Ethereum Smart Contracts. With this generator it should be possible for anyone to create own Smart Contracts.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://sap.com">
          <TimelineElementImage src={img3_4} alt="SAP SE" />
          <TimelineElementPanel time="Sep. 2017 - Feb. 2018" name={'Internship: SAP SE'}>
            <p className="text-muted">As part of my practial semester of my university I did an internship at SAP. There I worked in a team of students developing software mainly in SAPUi5 and XSJS (mostly JavaScript).</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="http://www.fliesen-joerke.de/">
          <TimelineElementImage src={img3_3} alt="Jörke warehouse management" />
          <TimelineElementPanel time="April - July 2017" name={'Jörke warehouse management'}>
            <p className="text-muted">A projekt at the <a href="https://fiw.fhws.de/" target="_blank" rel="noopener noreferrer">FHWS</a> that should enhance the warehoue management of <a href="http://www.fliesen-joerke.de/" target="_blank" rel="noopener noreferrer">Fliesen Jörke</a>. Therefore we developed an android app as extension for the open source ERP and CRM <a href="https://www.odoo.com/" target="_blank" rel="noopener noreferrer">Odoo</a>.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://www.wuerzburg.ihk.de/ausbilder-werden.html">
          <TimelineElementText>Instructor certificate</TimelineElementText>
          <TimelineElementPanel time="March - July 2017" name={'Instructor Certificate (Ausbilderschein)'}>
            <p className="text-muted">In addition to my studies, I participated in a course and confirmed my professional and work-related aptitude in a corresponding exam. This makes it possible for me in Germany to carry out vocational schooling.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://www.ics-ev.de">
          <TimelineElementText>International co-operative studies</TimelineElementText>
          <TimelineElementPanel time="March 2017" name={'Nominated for dual I.C.S. Model'}>
            <p className="text-muted">As on of the best students in my semestre, I was nominated for the I.C.S. Model. This is a system to connect students with companies. Students can work there during semester holidays or practical semesters and were paid for this and even for studiing.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://www.facebook.com/fhwsigp2017">
          <TimelineElementImage src={img3_1} alt="India Gateway Project" />
          <TimelineElementPanel time="October 2016 - March 2017" name={'India Gateway Project'}>
            <p className="text-muted">As a group of about 15 colleagues I traveled to India for one month to work with exchange students from <a href="https://christuniversity.in" target="_blank" rel="noopener noreferrer">Christ University</a> on a technical Project. We also visitied some local companies including <a href="https://www.sap.com/india/index.html" target="_blank" rel="noopener noreferrer">SAP</a>, <a href="https://www.infosys.com/" target="_blank" rel="noopener noreferrer">Infosys</a> & <a href="http://www.himalayawellness.com/" target="_blank" rel="noopener noreferrer">Himalaya Drugs</a>.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="https://fiw.fhws.de">
          <TimelineElementImage src={img3} alt="University of Applied Sciences Würzburg-Schweinfurt" />
          <TimelineElementPanel time="October 2015 - March 2019" name={'Bachelor:\nUniversity of Applied Sciences\nWürzburg-Schweinfurt'}>
            <p className="text-muted">I was studiing Computer Science at Bachelor level at University of Applied Sciences Würzburg-Schweinfurt.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="http://evdhg.de">
          <TimelineElementText>High<br />school<br />graduation</TimelineElementText>
          <TimelineElementPanel time="June 2015" name={'High School Graduation (Abitur)'}>
            <p className="text-muted">Graduation exam at the high school. With this exam you get access to universities.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="http://peng.evdhg.de">
          <TimelineElementImage src={img2_5} alt="PENG" />
          <TimelineElementPanel time="2013 - 2015" name={'PENG'}>
            <p className="text-muted">I was part of the PENG team that plans and acts a physic show. Also check out the <a target="_blank" rel="noopener noreferrer" href="https://www.mainpost.de/regional/wuerzburg/Schueler-spenden-fuer-Bahnhofsmission;art736,8937423,B::pic17683,6803853">report in the local paper</a>.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="http://evdhg.de/schueler/smv">
          <TimelineElementText>Pupil<br />council</TimelineElementText>
          <TimelineElementPanel time="2013 - 2015" name={'Pupil Council'}>
            <p className="text-muted">During this years I was part of the pupil counil. We were responsible for different school events an helped pupils to solve problems with teachers.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="http://evdhg.de">
          <TimelineElementText>Stagecraft<br />at<br />school</TimelineElementText>
          <TimelineElementPanel time="2010 - 2015" name={'Stagecraft at school'}>
            <p className="text-muted">During these years I was responsible for keeping the stage equipment running on school events.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="http://evdhg.de">
          <TimelineElementText>Website<br />administration</TimelineElementText>
          <TimelineElementPanel time="2011-2013" name={'School website administration'}>
            <p className="text-muted">I was part of the website team of my school. We did not only publish new articels on the web page, we also changed the Contend Management Sytem during this time.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={true} href="https://www.lego.com/en-us/mindstorms">
          <TimelineElementImage src={img2_1} alt="Lego Mindstorme" />
          <TimelineElementPanel time="2007 - 2010" name={'Robotik'}>
            <p className="text-muted">In this additional course we build and programmed Lego Mindstorme Roboters.</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="http://evdhg.de">
          <TimelineElementText>High School<br />Würzburg</TimelineElementText>
          <TimelineElementPanel time="Sep. 2007 - June 2015" name={'High School:\nMozart-Schönborn-Gymnasium'}>
            <p className="text-muted">(or later Dag Hammarsjköld Gymnasium)<br />My education until I reached my High School Graduation (Abitur).</p>
          </TimelineElementPanel>
        </TimelineElement>
        <TimelineElement inverted={false} href="http://grundschule-lengfeld.de">
          <TimelineElementImage src={img1} alt="Grundschule Würzburg-Lengfeld" />
          <TimelineElementPanel time="Sep. 2003 - July 2007" name={'Primary School:\nGrundschule\nWürzburg-Lengfeld'}>
            <p className="text-muted">Primary school education.</p>
          </TimelineElementPanel>
        </TimelineElement>
      </Timeline >
    );
  }
}

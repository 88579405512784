import React, {Component} from 'react';

export default class TimelineElement extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    if (!this.props.href || e.target.tagName === 'A') {
      return;
    }
    console.log('i');
    if (this.props.href.startsWith('#')) {
      window.location.assign(this.props.href);
    } else {
      window.open(this.props.href);
    }
  }

  render() {
    return (
      <li className={' custom-link-hovering ' + (this.props.inverted ? ' timeline-inverted' : '')} onClick={this.onClick}
        style={{cursor: this.props.href ? 'pointer' : 'default '}}>
        {this.props.children}
      </li>
    );
  }
}

import React, {Component} from 'react';
import {PortfolioElementCard, PortfolioElementModal} from '../PortfolioElement/PortfolioElement';
import {FactList, FactListItem} from '../PortfolioElement/FactList';
import Lightbox from '../../../../components/Lighbox';
import imgThumbnail from './thumbnail.jpg';
import imgFull from './full.png';
import imgDetailProjectOverview from './detail-project_overview.png';
import imgDetailProjectView from './detail-projectview.png';
import imgDetailSearch from './detail-search.png';

const id='codeforest';

export class Card extends Component {
  render() {
    return (
      <PortfolioElementCard id={id} name="Code Forest" thumbnail={imgThumbnail} thumbnailAlt="Code Forest">
        A visualization for software quality metrics
      </PortfolioElementCard>
    );
  }
}

const IMAGES = [
  {
    src: imgFull,
    caption: 'Code Forest',
  },
  {
    src: imgDetailProjectOverview,
    caption: 'Overview of different projects',
  },
  {
    src: imgDetailSearch,
    caption: 'Search in a project',
  },
  {
    src: imgDetailProjectView,
    caption: 'Project metric visualization',
  },
];

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.setPhotoIndex = this.setPhotoIndex.bind(this);

    this.state = {
      photoIndex: false,
    };
  }

  setPhotoIndex(index) {
    this.setState({photoIndex: index});
  }

  render() {
    return (
      <PortfolioElementModal id={id} name="Code Forest" description="A visualization for software quality metrics">
        <Lightbox images={IMAGES} photoIndex={this.state.photoIndex} onPhotoIndexChange={this.setPhotoIndex} />
        <img className="img-fluid border img-clickable" src={imgFull} alt="Smart Contract Generator" onClick={() => this.setPhotoIndex(0)} />

        <p>
          This project is available on <a href="https://gitlab.com/msp2019" target="_blank" rel="noopener noreferrer">Gitlab</a> and can be executed using <a href="https://docs.docker.com/compose" target="_blank" rel="noopener noreferrer">docker-compose</a>!
          <br />
          For more information visit the <a href="https://gitlab.com/msp2019/project/blob/master/README.md" target="_blank" rel="noopener noreferrer">README</a>.
        </p>
        <p>
          As part of the Master Software Project of <a href="https://www.informatik-master.hs-mannheim.de" target="_blank" rel="noopener noreferrer">University of Applied Sciences Mannheim</a> we created a visualization solution that allows people without technical background to understand different code quality metrics.
          <br />
          This project was realized for <a href="https://jobs.schwarz" target="_blank" rel="noopener noreferrer">Schwarz IT</a> to allow them to visualize their software quality to estimate the time for adding new features
        </p>
        <p>
          To make it extendable we created an application that allowes to store and display metrics the metrics.
          <br />
          Beside this we built an Adapter to store metric data into our applications, because that makes it easier for anyone to add new metrics to the application.
          For our project we just built the adapter for <a href="https://sonarcloud.io" target="_blank" rel="noopener noreferrer">SonarCloud</a>.
        </p>
        <h3>Screenshots</h3>
        <div className="row justify-content-md-center">
          <div className="col col-md-4 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailProjectOverview} alt="Overview of different projects" onClick={() => this.setPhotoIndex(1)} />
          </div>
          <div className="col col-md-4 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailSearch} alt="Search in a project" onClick={() => this.setPhotoIndex(3)} />
          </div>
          <div className="col col-md-4 col-sm-12 col-12">
            <img className="img-fluid border img-clickable" src={imgDetailProjectView} alt="Project metric visualization" onClick={() => this.setPhotoIndex(2)} />
          </div>
        </div>

        <FactList>
          <FactListItem>Co-workers: <a href="http://kolar.info" target="_blank" rel="noopener noreferrer">Gabriel</a>, <a href="http://patrice.codes" target="_blank" rel="noopener noreferrer">Patrice</a>, <a href="https://gitlab.com/users/Glurry" target="_blank" rel="noopener noreferrer">Rico</a>, <a href="https://github.com/pdomscheid" target="_blank" rel="noopener noreferrer">Patrick</a> & <a href="https://gitlab.com/groups/msp2019/-/group_members" target="_blank" rel="noopener noreferrer">10 more...</a></FactListItem>
          <FactListItem>Development: May - June 2019</FactListItem>
          <FactListItem>Client: <a href="https://jobs.schwarz" target="_blank" rel="noopener noreferrer">Schwarz IT</a>, <a href="https://www.exxeta.com" target="_blank" rel="noopener noreferrer">Exxeta</a> & <a href="https://www.informatik-master.hs-mannheim.de" target="_blank" rel="noopener noreferrer">Computer Science Faculty HS Mannheim</a></FactListItem>
          <FactListItem>Category: Software Developement</FactListItem>
          <FactListItem>External information: <a href="https://gitlab.com/msp2019" target="_blank" rel="noopener noreferrer">Gitlab</a></FactListItem>
        </FactList>
      </PortfolioElementModal >
    );
  }
}

export default {Card, Modal};

import React, {Component} from 'react';
import {PortfolioElementCard, PortfolioElementModal} from '../PortfolioElement/PortfolioElement';
import {FactList, FactListItem} from '../PortfolioElement/FactList';
import Lightbox from '../../../../components/Lighbox';
import imgThumbnail from './thumbnail.jpg';
import imgFull from './full.jpg';
import imgDetailProductErstellen from './detail-produkt-erstellen.png';
import imgDetailEinlagern from './detail-einlagern.png';
import imgDetailProductEinlagern from './detail-product-einlagern.png';
import imgDetailBestellen from './detail-bestellen.png';
import imgDetailAuslagern from './detail-auslagern.png';

const id='joerke';

export class Card extends Component {
  render() {
    return (
      <PortfolioElementCard id={id} name="Jörke warehouse management" thumbnail={imgThumbnail} thumbnailAlt="Jörke warehouse management">
        A app with backend api as extension for Odoo all-in-one management software
      </PortfolioElementCard>
    );
  }
}

const IMAGES = [
  {
    src: imgFull,
    caption: 'Jörke warehouse management',
  },
  {
    src: imgDetailProductErstellen,
    caption: 'Create product',
  },
  {
    src: imgDetailEinlagern,
    caption: 'List of available products',
  },
  {
    src: imgDetailProductEinlagern,
    caption: 'Store product',
  },
  {
    src: imgDetailBestellen,
    caption: 'Order product',
  },
  {
    src: imgDetailAuslagern,
    caption: 'Unstore ordered products',
  },
];

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.setPhotoIndex = this.setPhotoIndex.bind(this);

    this.state = {
      photoIndex: false,
    };
  }

  setPhotoIndex(index) {
    this.setState({photoIndex: index});
  }

  render() {
    return (
      <PortfolioElementModal id={id} name="Jörke warehouse management" description="A app with backend api as extension for Odoo all-in-one management software">
        <Lightbox images={IMAGES} photoIndex={this.state.photoIndex} onPhotoIndexChange={this.setPhotoIndex} />
        <img className="img-fluid border img-clickable" src={imgFull} alt="Jörke warehouse management" onClick={() => this.setPhotoIndex(0)} />

        <p>During my studies at the <a href="http://fiw.fhws.de" target="_blank" rel="noopener noreferrer">FHWS</a> I did a project in cooperation with <a href="http://www.fliesen-joerke.de/" target="_blank" rel="noopener noreferrer">Fliesen Jörke</a>.</p>
        <p>Our task was to implement a storage management solution for the internal stock. With this solution it should be possible to order stuff from the construction site, in the stock anyone should be able to unstore ordered products and ship it to the construction site.</p>

        <p>To provide an easy to use solution we decided to create an android app. This andoid app was designed with large controle element to allow its operation anywhere by anyone.
          <br />
          The android app connects to <a href="https://www.odoo.com/" target="_blank" rel="noopener noreferrer">Odoo</a> using a self-developed api. We decided to use <a href="https://www.odoo.com/" target="_blank" rel="noopener noreferrer">Odoo</a> on top to allow the customer access additional functionality for the future. With <a href="https://www.odoo.com/" target="_blank" rel="noopener noreferrer">Odoo</a> the customer is able to controle the stock via pc and use it for other business management purposes.</p>

        <h3>Screenshots of the android app</h3>
        <div className="row justify-content-md-center">
          <div className="col col-md-2 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailProductErstellen} alt="Create product" onClick={() => this.setPhotoIndex(1)} />
          </div>
          <div className="col col-md-2 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailEinlagern} alt="List of available products" onClick={() => this.setPhotoIndex(2)} />
          </div>
          <div className="col col-md-2 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailProductEinlagern} alt="Store product" onClick={() => this.setPhotoIndex(3)} />
          </div>
          <div className="col col-md-2 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailBestellen} alt="Order product" onClick={() => this.setPhotoIndex(4)} />
          </div>
          <div className="col col-md-2 col-sm-6 col-6">
            <img className="img-fluid border img-clickable" src={imgDetailAuslagern} alt="Unstore ordered products" onClick={() => this.setPhotoIndex(5)} />
          </div>
        </div>

        <FactList>
          <FactListItem>Co-workers: <a href="https://github.com/SebastianFreund" target="_blank" rel="noopener noreferrer">Sebastian F.</a>, <a href="https://github.com/Sebb767" target="_blank" rel="noopener noreferrer">Sebastian K.</a> and <a href="https://github.com/VeebD" target="_blank" rel="noopener noreferrer">Vincent</a></FactListItem>
          <FactListItem>Developement: April - July 2017</FactListItem>
          <FactListItem>Client: <a href="http://www.fliesen-joerke.de/" target="_blank" rel="noopener noreferrer">Fliesen Jörke</a></FactListItem>
          <FactListItem>Category: Software Developement</FactListItem>
        </FactList>
      </PortfolioElementModal>
    );
  }
}

export default {Card, Modal};
